import React, { useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { Produto, ItemKit } from '../../../types';
import { useStore } from '../../../store/useStore';

interface FormularioProdutoProps {
  produto: Produto | null;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onCancel: () => void;
}

export const FormularioProduto: React.FC<FormularioProdutoProps> = ({
  produto,
  onSubmit,
  onCancel
}) => {
  const { produtos } = useStore();
  const [tipoProduto, setTipoProduto] = useState<'produto' | 'kit'>(produto?.tipo || 'produto');
  const [itensKit, setItensKit] = useState<ItemKit[]>(produto?.itensKit || []);
  const [precoPersonalizado, setPrecoPersonalizado] = useState(produto?.preco || 0);
  const [usarPrecoCalculado, setUsarPrecoCalculado] = useState(!produto);
  const produtosDisponiveis = produtos.filter(p => p.ativo && p.tipo === 'produto');

  const adicionarItemKit = () => {
    setItensKit([...itensKit, { produtoId: '', quantidade: 1 }]);
  };

  const removerItemKit = (index: number) => {
    setItensKit(itensKit.filter((_, i) => i !== index));
  };

  const atualizarItemKit = (index: number, campo: keyof ItemKit, valor: string | number) => {
    const novosItens = [...itensKit];
    novosItens[index] = { ...novosItens[index], [campo]: valor };
    setItensKit(novosItens);
  };

  const calcularPrecoKit = () => {
    return itensKit.reduce((total, item) => {
      const produto = produtos.find(p => p.id === item.produtoId);
      return total + (produto?.preco || 0) * item.quantidade;
    }, 0);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    formData.append('tipo', tipoProduto);
    if (tipoProduto === 'kit') {
      formData.append('itensKit', JSON.stringify(itensKit));
      formData.set('preco', usarPrecoCalculado ? calcularPrecoKit().toString() : precoPersonalizado.toString());
    }
    onSubmit(e);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Tipo
        </label>
        <select
          value={tipoProduto}
          onChange={(e) => setTipoProduto(e.target.value as 'produto' | 'kit')}
          className="w-full px-3 py-2 border rounded-md"
          required
        >
          <option value="produto">Produto Individual</option>
          <option value="kit">Kit de Produtos</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Nome
        </label>
        <input
          type="text"
          name="nome"
          defaultValue={produto?.nome}
          className="w-full px-3 py-2 border rounded-md"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Código (opcional)
        </label>
        <input
          type="text"
          name="codigo"
          defaultValue={produto?.codigo}
          className="w-full px-3 py-2 border rounded-md"
          placeholder="Digite um código de referência"
        />
      </div>

      {tipoProduto === 'kit' && (
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <label className="block text-sm font-medium text-gray-700">
              Itens do Kit
            </label>
            <button
              type="button"
              onClick={adicionarItemKit}
              className="text-indigo-600 hover:text-indigo-800 flex items-center gap-1 text-sm"
            >
              <Plus className="w-4 h-4" />
              Adicionar Item
            </button>
          </div>

          {itensKit.map((item, index) => (
            <div key={index} className="flex gap-4 items-start">
              <div className="flex-1">
                <select
                  value={item.produtoId}
                  onChange={(e) => atualizarItemKit(index, 'produtoId', e.target.value)}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                >
                  <option value="">Selecione um produto</option>
                  {produtosDisponiveis.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.nome} - {p.preco.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-32">
                <input
                  type="number"
                  value={item.quantidade}
                  onChange={(e) => atualizarItemKit(index, 'quantidade', Number(e.target.value))}
                  min="1"
                  className="w-full px-3 py-2 border rounded-md"
                  placeholder="Qtd"
                  required
                />
              </div>
              <button
                type="button"
                onClick={() => removerItemKit(index)}
                className="text-red-600 hover:text-red-800 p-2"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          ))}
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Preço
        </label>
        {tipoProduto === 'kit' && (
          <div className="mb-2">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={usarPrecoCalculado}
                onChange={(e) => setUsarPrecoCalculado(e.target.checked)}
                className="rounded border-gray-300"
              />
              <span className="text-sm text-gray-600">
                Usar preço calculado automaticamente
              </span>
            </label>
          </div>
        )}
        <input
          type="number"
          name="preco"
          value={tipoProduto === 'kit' && usarPrecoCalculado ? calcularPrecoKit() : precoPersonalizado}
          onChange={(e) => setPrecoPersonalizado(Number(e.target.value))}
          min="0"
          step="0.01"
          className="w-full px-3 py-2 border rounded-md"
          required
          readOnly={tipoProduto === 'kit' && usarPrecoCalculado}
        />
        {tipoProduto === 'kit' && usarPrecoCalculado && (
          <p className="text-sm text-gray-500 mt-1">
            Preço calculado com base nos itens do kit: {calcularPrecoKit().toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            })}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Unidade
        </label>
        <select
          name="unidade"
          defaultValue={produto?.unidade}
          className="w-full px-3 py-2 border rounded-md"
          required
        >
          <option value="unidade">Unidade</option>
          <option value="kg">Quilograma (kg)</option>
          <option value="litro">Litro</option>
          <option value="metro">Metro</option>
        </select>
      </div>

      <div className="flex justify-end gap-4 mt-6">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md"
        >
          {produto ? 'Salvar Alterações' : 'Criar Produto'}
        </button>
      </div>
    </form>
  );
};