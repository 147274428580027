import React from 'react';
import { AlertCircle, CheckCircle, XCircle, Info } from 'lucide-react';

type TipoAlerta = 'sucesso' | 'erro' | 'info' | 'aviso';

interface AlertProps {
  tipo: TipoAlerta;
  mensagem: string;
  onClose?: () => void;
}

const configuracaoAlerta = {
  sucesso: {
    icone: CheckCircle,
    bgColor: 'bg-green-50',
    textColor: 'text-green-800',
    borderColor: 'border-green-200'
  },
  erro: {
    icone: XCircle,
    bgColor: 'bg-red-50',
    textColor: 'text-red-800',
    borderColor: 'border-red-200'
  },
  info: {
    icone: Info,
    bgColor: 'bg-blue-50',
    textColor: 'text-blue-800',
    borderColor: 'border-blue-200'
  },
  aviso: {
    icone: AlertCircle,
    bgColor: 'bg-yellow-50',
    textColor: 'text-yellow-800',
    borderColor: 'border-yellow-200'
  }
};

export const Alert: React.FC<AlertProps> = ({ tipo, mensagem, onClose }) => {
  const config = configuracaoAlerta[tipo];
  const Icon = config.icone;

  return (
    <div className={`${config.bgColor} ${config.textColor} p-4 rounded-md border ${config.borderColor}`}>
      <div className="flex items-center gap-3">
        <Icon className="w-5 h-5" />
        <p>{mensagem}</p>
        {onClose && (
          <button
            onClick={onClose}
            className="ml-auto hover:opacity-75"
          >
            <XCircle className="w-5 h-5" />
          </button>
        )}
      </div>
    </div>
  );
};