import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { KanbanCard } from '../../types';
import { KanbanCardComponent } from './KanbanCard';

interface SortableCardProps {
  card: KanbanCard;
  columnId: string;
  onEdit: (cardId: string, cardAtualizado: KanbanCard) => void;
}

export const SortableCard: React.FC<SortableCardProps> = ({ 
  card, 
  columnId,
  onEdit 
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: card.id,
    data: {
      type: 'card',
      card,
      columnId
    }
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="cursor-grab active:cursor-grabbing"
    >
      <KanbanCardComponent 
        card={card} 
        onEdit={(cardAtualizado) => onEdit(card.id, cardAtualizado)}
      />
    </div>
  );
};