import React from 'react';
import { LucideIcon } from 'lucide-react';

interface CardMetricaProps {
  titulo: string;
  valor: string | number;
  Icone: LucideIcon;
  corIcone: string;
}

export const CardMetrica: React.FC<CardMetricaProps> = ({
  titulo,
  valor,
  Icone,
  corIcone
}) => {
  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-gray-600 dark:text-gray-300">{titulo}</p>
          <p className="text-2xl font-bold text-gray-900 dark:text-white">{valor}</p>
        </div>
        <Icone className={`w-10 h-10 ${corIcone}`} />
      </div>
    </div>
  );
};