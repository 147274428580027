import React from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Meta, Venda } from '../../types';
import { calcularProgressoMeta } from '../../utils/metas';
import { BarraProgresso } from '../shared/BarraProgresso';

interface ListaMetasVendedorProps {
  metas: Meta[];
  vendas: Venda[];
}

export const ListaMetasVendedor: React.FC<ListaMetasVendedorProps> = ({
  metas,
  vendas
}) => {
  const metasOrdenadas = [...metas].sort((a, b) => 
    new Date(b.dataInicio).getTime() - new Date(a.dataInicio).getTime()
  );

  if (metasOrdenadas.length === 0) {
    return (
      <div className="text-center text-gray-500 py-8">
        Nenhuma meta definida ainda.
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {metasOrdenadas.map((meta) => {
        const progresso = calcularProgressoMeta(meta, vendas);
        
        return (
          <div key={meta.id} className="border rounded-lg p-4">
            <div className="flex justify-between items-start mb-4">
              <div>
                <p className="text-sm text-gray-500">
                  {format(new Date(meta.dataInicio), "dd 'de' MMMM", { locale: ptBR })} - 
                  {format(new Date(meta.dataFim), "dd 'de' MMMM", { locale: ptBR })}
                </p>
                <p className="font-medium mt-1">
                  Meta: R$ {meta.valorMeta.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                </p>
              </div>
              {meta.bonificacao && (
                <div className="text-right">
                  <p className="text-sm text-gray-500">Bonificação</p>
                  <p className={`font-medium ${progresso.metaBatida ? 'text-green-600' : 'text-yellow-600'}`}>
                    R$ {meta.bonificacao.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                  </p>
                </div>
              )}
            </div>

            <div className="space-y-4">
              <div>
                <div className="flex justify-between text-sm mb-1">
                  <span>Progresso em Valor</span>
                  <span>
                    {progresso.valorAtingido.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} / 
                    {meta.valorMeta.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                  </span>
                </div>
                <BarraProgresso 
                  progresso={progresso.progressoValor} 
                  corBarra="bg-green-600" 
                />
              </div>

              <div>
                <div className="flex justify-between text-sm mb-1">
                  <span>Progresso em Quantidade</span>
                  <span>
                    {progresso.quantidadeAtingida} / {meta.quantidadeMeta}
                  </span>
                </div>
                <BarraProgresso 
                  progresso={progresso.progressoQuantidade} 
                  corBarra="bg-blue-600" 
                />
              </div>
            </div>

            {progresso.metaBatida && (
              <div className="mt-4 pt-4 border-t text-center">
                <p className="text-green-600 font-medium">
                  🎉 Meta atingida! Parabéns!
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};