import React, { useState } from 'react';
import { ShoppingCart, Plus, Settings } from 'lucide-react';
import { useStore } from '../../../store/useStore';
import { FormularioVenda } from './FormularioVenda';
import { TabelaVendas } from './TabelaVendas';
import { FiltrosPeriodo } from './FiltrosPeriodo';
import { ExportarVendas } from './ExportarVendas';
import { ConfiguracaoWebhook } from './ConfiguracaoWebhook';
import { useNotificacoes } from '../../../contexts/NotificacoesContext';
import { Alert } from '../../shared/Alert';
import { Venda } from '../../../types';

export const GestaoVendas: React.FC = () => {
  const { vendas, usuarios, adicionarVenda, editarVenda } = useStore();
  const { adicionarNotificacaoVenda } = useNotificacoes();
  const [modalAberto, setModalAberto] = useState(false);
  const [webhookAberto, setWebhookAberto] = useState(false);
  const [erro, setErro] = useState<string | null>(null);
  const [vendaEditando, setVendaEditando] = useState<Venda | null>(null);
  const [filtros, setFiltros] = useState({
    dataInicio: '',
    dataFim: '',
    vendedorId: ''
  });

  // Get default vendor (first vendor in the list)
  const vendedorPadrao = usuarios.find(u => u.tipo === 'vendedor');

  // Process sales and assign default vendor if needed
  const vendasProcessadas = vendas.map(venda => {
    if (!venda.vendedorId && vendedorPadrao) {
      return {
        ...venda,
        vendedorId: vendedorPadrao.id
      };
    }
    return venda;
  });

  const handleSubmitVenda = (dadosVenda: any) => {
    try {
      // Ensure vendorId is set
      const vendaComVendedor = {
        ...dadosVenda,
        vendedorId: dadosVenda.vendedorId || vendedorPadrao?.id
      };

      if (vendaEditando) {
        editarVenda({ ...vendaComVendedor, id: vendaEditando.id });
      } else {
        adicionarVenda(vendaComVendedor);
        const vendedor = usuarios.find(u => u.id === vendaComVendedor.vendedorId);
        if (vendedor) {
          adicionarNotificacaoVenda(vendedor, vendaComVendedor.valor);
        }
      }

      setModalAberto(false);
      setVendaEditando(null);
    } catch (error) {
      setErro(error instanceof Error ? error.message : 'Erro ao registrar venda');
    }
  };

  const vendasFiltradas = vendasProcessadas.filter(venda => {
    const dataVenda = new Date(venda.data);
    const atendeFiltroData = (!filtros.dataInicio || dataVenda >= new Date(filtros.dataInicio)) &&
                            (!filtros.dataFim || dataVenda <= new Date(filtros.dataFim));
    const atendeFiltroVendedor = !filtros.vendedorId || venda.vendedorId === filtros.vendedorId;
    
    return atendeFiltroData && atendeFiltroVendedor;
  });

  return (
    <div className="p-6">
      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <ShoppingCart className="w-6 h-6 text-indigo-600 dark:text-indigo-400" />
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Gestão de Vendas</h2>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setWebhookAberto(true)}
            className="bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-2 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 flex items-center gap-2"
          >
            <Settings className="w-5 h-5" />
            Webhook
          </button>
          <button
            onClick={() => {
              setVendaEditando(null);
              setModalAberto(true);
            }}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 flex items-center gap-2"
          >
            <Plus className="w-5 h-5" />
            Nova Venda
          </button>
          <ExportarVendas vendasFiltradas={vendasFiltradas} />
        </div>
      </div>

      <FiltrosPeriodo
        filtros={filtros}
        setFiltros={setFiltros}
      />

      <div className="mt-6">
        <TabelaVendas 
          vendas={vendasFiltradas} 
          onEditar={(venda) => {
            setVendaEditando(venda);
            setModalAberto(true);
          }}
        />
      </div>

      {modalAberto && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
            <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">
              {vendaEditando ? 'Editar Venda' : 'Nova Venda'}
            </h3>
            <FormularioVenda
              venda={vendaEditando}
              onSubmit={handleSubmitVenda}
              onCancel={() => {
                setModalAberto(false);
                setVendaEditando(null);
              }}
            />
          </div>
        </div>
      )}

      {webhookAberto && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-2xl">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                Configuração de Webhook
              </h3>
              <button
                onClick={() => setWebhookAberto(false)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
              >
                ×
              </button>
            </div>
            <ConfiguracaoWebhook />
          </div>
        </div>
      )}
    </div>
  );
};