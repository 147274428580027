import React from 'react';
import { Trophy, Medal, TrendingUp } from 'lucide-react';
import { BarraProgresso } from '../../shared/BarraProgresso';
import { Usuario } from '../../../types';

interface GradeLayoutProps {
  vendedores: (Usuario & { totalVendas: number })[];
  getProgressoMeta: (vendedorId: string) => any;
}

export const GradeLayout: React.FC<GradeLayoutProps> = ({ vendedores, getProgressoMeta }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {vendedores.map((vendedor, index) => {
        const progresso = getProgressoMeta(vendedor.id);
        const posicao = index + 1;

        return (
          <div 
            key={vendedor.id}
            className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center transition-transform hover:scale-[1.02]"
          >
            <div className="relative mb-4">
              {posicao <= 3 && (
                <div className="absolute -top-2 -right-2 w-8 h-8 rounded-full flex items-center justify-center">
                  {posicao === 1 && <Trophy className="w-6 h-6 text-yellow-400" />}
                  {posicao === 2 && <Medal className="w-6 h-6 text-gray-400" />}
                  {posicao === 3 && <Medal className="w-6 h-6 text-amber-600" />}
                </div>
              )}
              {vendedor.foto ? (
                <img 
                  src={vendedor.foto} 
                  alt={vendedor.nome}
                  className="w-24 h-24 rounded-full object-cover border-4 border-gray-200"
                />
              ) : (
                <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center border-4 border-gray-100">
                  <span className="text-3xl font-bold text-gray-500">
                    {vendedor.nome.charAt(0)}
                  </span>
                </div>
              )}
            </div>

            <div className="flex items-center gap-2 mb-2">
              <span className="text-xl font-bold text-gray-700">{posicao}º</span>
              <h3 className="text-lg font-bold text-gray-800">{vendedor.nome}</h3>
            </div>

            <p className="text-lg text-indigo-600 font-semibold mb-4">
              R$ {vendedor.totalVendas.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
            </p>

            {progresso && (
              <div className="w-full">
                <div className="flex justify-between text-sm text-gray-600 mb-1">
                  <span>Meta</span>
                  <span>{progresso.progressoValor.toFixed(1)}%</span>
                </div>
                <BarraProgresso 
                  progresso={progresso.progressoValor}
                  corBarra="bg-indigo-600"
                  altura="h-2"
                  animada={true}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};