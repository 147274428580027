import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Usuario } from '../../../types';
import { Alert } from '../../shared/Alert';

interface NovoVendedorModalProps {
  vendedor?: Usuario | null;
  onClose: () => void;
  onSubmit: (dados: any) => void;
}

export const NovoVendedorModal: React.FC<NovoVendedorModalProps> = ({
  vendedor,
  onClose,
  onSubmit
}) => {
  const [formData, setFormData] = useState({
    nome: vendedor?.nome || '',
    email: vendedor?.email || '',
    telefone: vendedor?.telefone || '',
    senha: ''
  });
  const [erro, setErro] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErro(null);

    if (!vendedor && !formData.senha) {
      setErro('Senha é obrigatória para novos vendedores');
      return;
    }

    onSubmit(formData);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">
            {vendedor ? 'Editar Vendedor' : 'Novo Vendedor'}
          </h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        {erro && (
          <Alert 
            tipo="erro" 
            mensagem={erro} 
            onClose={() => setErro(null)} 
          />
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Nome
            </label>
            <input
              type="text"
              value={formData.nome}
              onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
              className="w-full px-3 py-2 border rounded-md"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="w-full px-3 py-2 border rounded-md"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Telefone (opcional)
            </label>
            <input
              type="tel"
              value={formData.telefone}
              onChange={(e) => setFormData({ ...formData, telefone: e.target.value })}
              className="w-full px-3 py-2 border rounded-md"
              placeholder="(00) 00000-0000"
            />
          </div>

          {!vendedor && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Senha
              </label>
              <input
                type="password"
                value={formData.senha}
                onChange={(e) => setFormData({ ...formData, senha: e.target.value })}
                className="w-full px-3 py-2 border rounded-md"
                required={!vendedor}
                minLength={6}
              />
              <p className="text-sm text-gray-500 mt-1">
                Mínimo de 6 caracteres
              </p>
            </div>
          )}

          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md"
            >
              {vendedor ? 'Salvar Alterações' : 'Criar Vendedor'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};