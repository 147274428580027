import React from 'react';
import { Activity, CheckCircle, XCircle, Clock } from 'lucide-react';
import { WebhookStats as Stats } from '../../../types/webhook';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface WebhookStatsProps {
  stats: Stats;
  modo: 'test' | 'production';
}

export const WebhookStats: React.FC<WebhookStatsProps> = ({ stats, modo }) => {
  const formatarData = (data?: Date) => {
    if (!data) return 'Nunca';
    return format(new Date(data), "dd/MM/yyyy 'às' HH:mm", { locale: ptBR });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-between mb-4">
        <h4 className="font-medium flex items-center gap-2">
          <Activity className="w-5 h-5 text-indigo-600" />
          <span>Estatísticas ({modo === 'test' ? 'Teste' : 'Produção'})</span>
        </h4>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <Activity className="w-4 h-4 text-gray-600" />
            <span className="text-sm font-medium text-gray-700">Total de Requisições</span>
          </div>
          <p className="text-2xl font-bold text-indigo-600">{stats.totalRequests}</p>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <CheckCircle className="w-4 h-4 text-green-600" />
            <span className="text-sm font-medium text-gray-700">Sucesso</span>
          </div>
          <p className="text-2xl font-bold text-green-600">{stats.successCount}</p>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <XCircle className="w-4 h-4 text-red-600" />
            <span className="text-sm font-medium text-gray-700">Erros</span>
          </div>
          <p className="text-2xl font-bold text-red-600">{stats.errorCount}</p>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <Clock className="w-4 h-4 text-blue-600" />
            <span className="text-sm font-medium text-gray-700">Última Requisição</span>
          </div>
          <p className="text-sm font-medium text-blue-600">
            {formatarData(stats.lastRequest)}
          </p>
        </div>
      </div>
    </div>
  );
};