import React from 'react';
import { Edit, Trash2 } from 'lucide-react';
import { Usuario } from '../../../types';

interface TabelaUsuariosProps {
  usuarios: Usuario[];
  onEditar: (usuario: Usuario) => void;
  onRemover: (id: string) => void;
}

export const TabelaUsuarios: React.FC<TabelaUsuariosProps> = ({
  usuarios,
  onEditar,
  onRemover
}) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Nome
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tipo
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Ações
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {usuarios.map((usuario) => (
            <tr key={usuario.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  {usuario.foto && (
                    <img
                      src={usuario.foto}
                      alt={usuario.nome}
                      className="w-8 h-8 rounded-full mr-3"
                    />
                  )}
                  <div className="text-sm font-medium text-gray-900">
                    {usuario.nome}
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {usuario.email}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {usuario.tipo}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button
                  onClick={() => onEditar(usuario)}
                  className="text-indigo-600 hover:text-indigo-900 mr-3"
                >
                  <Edit className="w-5 h-5" />
                </button>
                <button
                  onClick={() => onRemover(usuario.id)}
                  className="text-red-600 hover:text-red-900"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};