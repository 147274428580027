import React from 'react';
import { Clock, CheckCircle, XCircle } from 'lucide-react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { WebhookRequest } from '../../../types/webhook';

interface WebhookRequestLogProps {
  requests: WebhookRequest[];
}

export const WebhookRequestLog: React.FC<WebhookRequestLogProps> = ({ requests = [] }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold flex items-center gap-2">
        <Clock className="w-5 h-5 text-indigo-600" />
        Histórico de Requisições
      </h3>

      {requests.length === 0 ? (
        <div className="text-center py-8 bg-gray-50 rounded-lg">
          <p className="text-gray-500">Nenhuma requisição recebida ainda</p>
        </div>
      ) : (
        <div className="space-y-4">
          {requests.map((request) => (
            <div key={request.id} className="bg-white rounded-lg shadow-md p-4">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  {request.sucesso ? (
                    <CheckCircle className="w-5 h-5 text-green-600" />
                  ) : (
                    <XCircle className="w-5 h-5 text-red-600" />
                  )}
                  <span className="text-sm text-gray-600">
                    {format(new Date(request.timestamp), "dd/MM/yyyy 'às' HH:mm:ss", { locale: ptBR })}
                  </span>
                </div>
                <button
                  className="text-indigo-600 hover:text-indigo-800 text-sm flex items-center gap-1"
                  onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(request.payload, null, 2));
                  }}
                >
                  Copiar Payload
                </button>
              </div>

              <div className="space-y-2">
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-1">Headers</h4>
                  <pre className="text-xs bg-gray-50 p-2 rounded overflow-x-auto">
                    {JSON.stringify(request.headers, null, 2)}
                  </pre>
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-1">Payload</h4>
                  <pre className="text-xs bg-gray-50 p-2 rounded overflow-x-auto">
                    {JSON.stringify(request.payload, null, 2)}
                  </pre>
                </div>

                {request.erro && (
                  <div>
                    <h4 className="text-sm font-medium text-red-600 mb-1">Erro</h4>
                    <pre className="text-xs bg-red-50 text-red-600 p-2 rounded overflow-x-auto">
                      {request.erro}
                    </pre>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};