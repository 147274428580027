import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { KanbanColuna, KanbanCard } from '../../types';
import { SortableCard } from './SortableCard';

interface KanbanColumnProps {
  coluna: KanbanColuna;
  cards: KanbanCard[];
  onEditCard: (cardId: string, cardAtualizado: KanbanCard) => void;
}

export const KanbanColumn: React.FC<KanbanColumnProps> = ({ 
  coluna, 
  cards,
  onEditCard 
}) => {
  const { setNodeRef } = useDroppable({
    id: coluna.id,
    data: {
      type: 'column',
      columnId: coluna.id
    }
  });

  return (
    <div ref={setNodeRef} className="min-h-[200px]">
      <SortableContext items={cards.map(card => card.id)} strategy={verticalListSortingStrategy}>
        <div className="space-y-3">
          {cards.map(card => (
            <SortableCard
              key={card.id}
              card={card}
              columnId={coluna.id}
              onEdit={onEditCard}
            />
          ))}
        </div>
      </SortableContext>
    </div>
  );
};