import React, { useState, useEffect } from 'react';
import { UserPlus } from 'lucide-react';
import { useStore } from '../../store/useStore';
import { Usuario } from '../../types';
import { TabelaUsuarios } from './usuarios/TabelaUsuarios';
import { FormularioUsuario } from './usuarios/FormularioUsuario';

export const GestaoUsuarios: React.FC = () => {
  const { usuarios, adicionarUsuario, editarUsuario, removerUsuario } = useStore();
  const [modalAberto, setModalAberto] = useState(false);
  const [usuarioEditando, setUsuarioEditando] = useState<Usuario | null>(null);
  
  // Estado inicial do formulário
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    tipo: 'vendedor',
    senha: '',
    foto: ''
  });

  // Atualiza o formData quando um usuário é selecionado para edição
  useEffect(() => {
    if (usuarioEditando) {
      setFormData({
        nome: usuarioEditando.nome,
        email: usuarioEditando.email,
        telefone: usuarioEditando.telefone || '',
        tipo: usuarioEditando.tipo,
        senha: usuarioEditando.senha,
        foto: usuarioEditando.foto || ''
      });
    } else {
      // Limpa o formulário quando não está editando
      setFormData({
        nome: '',
        email: '',
        telefone: '',
        tipo: 'vendedor',
        senha: '',
        foto: ''
      });
    }
  }, [usuarioEditando]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (usuarioEditando) {
      // Mantém o ID e a senha original ao editar
      editarUsuario({
        ...usuarioEditando,
        ...formData,
        senha: usuarioEditando.senha // Mantém a senha original
      });
    } else {
      adicionarUsuario(formData);
    }
    
    setModalAberto(false);
    setUsuarioEditando(null);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Gestão de Usuários</h2>
        <button
          onClick={() => {
            setUsuarioEditando(null);
            setModalAberto(true);
          }}
          className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 flex items-center gap-2"
        >
          <UserPlus className="w-5 h-5" />
          Novo Usuário
        </button>
      </div>

      <TabelaUsuarios
        usuarios={usuarios}
        onEditar={(usuario) => {
          setUsuarioEditando(usuario);
          setModalAberto(true);
        }}
        onRemover={removerUsuario}
      />

      {modalAberto && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <h3 className="text-xl font-bold mb-4">
              {usuarioEditando ? 'Editar Usuário' : 'Novo Usuário'}
            </h3>
            <FormularioUsuario
              formData={formData}
              setFormData={setFormData}
              onSubmit={handleSubmit}
              onCancel={() => {
                setModalAberto(false);
                setUsuarioEditando(null);
              }}
              modoEdicao={!!usuarioEditando}
              usuarioEditando={usuarioEditando}
            />
          </div>
        </div>
      )}
    </div>
  );
};