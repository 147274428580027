import React, { useState } from 'react';
import { Users, DollarSign, ShoppingCart, TrendingUp, Package } from 'lucide-react';
import { useStore } from '../../store/useStore';
import { CardMetrica } from './dashboard/CardMetrica';
import { GraficoVendasMensais } from './dashboard/GraficoVendasMensais';
import { GraficoVendasPorVendedor } from './dashboard/GraficoVendasPorVendedor';
import { FiltrosPeriodo } from '../dashboard/FiltrosPeriodo';
import { isWithinInterval, startOfMonth, endOfMonth } from 'date-fns';

export function DashboardAdmin() {
  // Estado global da aplicação
  const { usuarios, vendas, produtos } = useStore();
  
  // Estados locais para controle dos filtros de período
  const [periodo, setPeriodo] = useState('mes');
  const [dataInicio, setDataInicio] = useState(startOfMonth(new Date()));
  const [dataFim, setDataFim] = useState(endOfMonth(new Date()));
  
  // Filtra vendedores ativos
  const vendedores = usuarios.filter(u => u.tipo === 'vendedor' && u.ativo);

  // Filtra vendas dentro do período selecionado
  const vendasFiltradas = vendas.filter(venda => 
    isWithinInterval(new Date(venda.data), { 
      start: dataInicio, 
      end: dataFim 
    })
  );

  // Calcula métricas totais
  const totalVendasValor = vendasFiltradas.reduce((acc, venda) => acc + venda.valor, 0);
  const totalVendasQuantidade = vendasFiltradas.reduce((acc, venda) => acc + venda.quantidade, 0);
  const mediaVendas = totalVendasValor / (vendasFiltradas.length || 1);
  const produtosAtivos = produtos.filter(p => p.ativo).length;

  // Array com as métricas principais
  const metricas = [
    {
      titulo: 'Faturamento Total',
      valor: totalVendasValor.toLocaleString('pt-BR', { 
        style: 'currency', 
        currency: 'BRL' 
      }),
      Icone: DollarSign,
      corIcone: 'text-green-600'
    },
    {
      titulo: 'Quantidade Vendida',
      valor: totalVendasQuantidade,
      Icone: ShoppingCart,
      corIcone: 'text-blue-600'
    },
    {
      titulo: 'Ticket Médio',
      valor: mediaVendas.toLocaleString('pt-BR', { 
        style: 'currency', 
        currency: 'BRL' 
      }),
      Icone: TrendingUp,
      corIcone: 'text-indigo-600'
    },
    {
      titulo: 'Vendedores Ativos',
      valor: vendedores.length,
      Icone: Users,
      corIcone: 'text-purple-600'
    },
    {
      titulo: 'Produtos Ativos',
      valor: produtosAtivos,
      Icone: Package,
      corIcone: 'text-orange-600'
    }
  ];

  return (
    <div className="p-6 space-y-6">
      <h2 className="text-2xl font-bold text-gray-900">
        Dashboard Administrativo
      </h2>
      
      {/* Componente de filtros de período */}
      <FiltrosPeriodo
        periodo={periodo}
        dataInicio={dataInicio}
        dataFim={dataFim}
        onPeriodoChange={setPeriodo}
        onDataInicioChange={setDataInicio}
        onDataFimChange={setDataFim}
      />
      
      {/* Grid de cards com métricas */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
        {metricas.map((metrica, index) => (
          <CardMetrica key={index} {...metrica} />
        ))}
      </div>

      {/* Gráficos */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <GraficoVendasMensais vendas={vendasFiltradas} />
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <GraficoVendasPorVendedor vendas={vendasFiltradas} usuarios={usuarios} />
        </div>
      </div>
    </div>
  );
}