import React, { createContext, useContext, useState, useCallback } from 'react';
import { useSom } from '../hooks/useSom';
import { Usuario } from '../types';

interface Notificacao {
  id: string;
  mensagem: string;
  tipo: 'venda' | 'ranking' | 'meta';
  timestamp: Date;
}

interface ContextoNotificacoes {
  notificacoes: Notificacao[];
  adicionarNotificacaoVenda: (vendedor: Usuario, valor: number) => void;
  adicionarNotificacaoRanking: (vendedor: Usuario, posicaoAnterior: number, novaPosicao: number) => void;
  adicionarNotificacaoMeta: (vendedor: Usuario) => void;
  removerNotificacao: (id: string) => void;
}

const NotificacoesContext = createContext<ContextoNotificacoes | undefined>(undefined);

export function NotificacoesProvider({ children }: { children: React.ReactNode }) {
  const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);
  const { tocarSom } = useSom();

  const adicionarNotificacao = useCallback((notificacao: Omit<Notificacao, 'id' | 'timestamp'>) => {
    const novaNotificacao = {
      ...notificacao,
      id: Date.now().toString(),
      timestamp: new Date()
    };

    setNotificacoes(prev => [novaNotificacao, ...prev].slice(0, 5));
    tocarSom(notificacao.tipo);
  }, [tocarSom]);

  const adicionarNotificacaoVenda = useCallback((vendedor: Usuario, valor: number) => {
    adicionarNotificacao({
      mensagem: `${vendedor.nome} realizou uma venda de R$ ${valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
      tipo: 'venda'
    });
  }, [adicionarNotificacao]);

  const adicionarNotificacaoRanking = useCallback((
    vendedor: Usuario,
    posicaoAnterior: number,
    novaPosicao: number
  ) => {
    adicionarNotificacao({
      mensagem: `${vendedor.nome} subiu da ${posicaoAnterior}ª para ${novaPosicao}ª posição!`,
      tipo: 'ranking'
    });
  }, [adicionarNotificacao]);

  const adicionarNotificacaoMeta = useCallback((vendedor: Usuario) => {
    adicionarNotificacao({
      mensagem: `${vendedor.nome} atingiu a meta estabelecida!`,
      tipo: 'meta'
    });
  }, [adicionarNotificacao]);

  const removerNotificacao = useCallback((id: string) => {
    setNotificacoes(prev => prev.filter(n => n.id !== id));
  }, []);

  return (
    <NotificacoesContext.Provider
      value={{
        notificacoes,
        adicionarNotificacaoVenda,
        adicionarNotificacaoRanking,
        adicionarNotificacaoMeta,
        removerNotificacao
      }}
    >
      {children}
    </NotificacoesContext.Provider>
  );
}

export function useNotificacoes() {
  const context = useContext(NotificacoesContext);
  if (!context) {
    throw new Error('useNotificacoes deve ser usado dentro de NotificacoesProvider');
  }
  return context;
}