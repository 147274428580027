import React, { useState } from 'react';
import { Copy, CheckCircle } from 'lucide-react';
import { Alert } from '../../shared/Alert';

export const ConfiguracaoWebhook: React.FC = () => {
  const [copiado, setCopiado] = useState(false);
  const [erro, setErro] = useState<string | null>(null);

  const webhookUrl = new URL('/api/webhook/vendas', window.location.origin).toString();

  const exemploPayload = {
    vendedorId: "exemplo_vendedor_1",
    valor: 1000.00,
    quantidade: 1,
    data: new Date().toISOString(),
    utm_source: "vendedor_123"
  };

  const copiarUrl = async () => {
    try {
      await navigator.clipboard.writeText(webhookUrl);
      setCopiado(true);
      setTimeout(() => setCopiado(false), 2000);
    } catch (erro) {
      setErro('Erro ao copiar URL');
    }
  };

  return (
    <div className="space-y-6">
      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      <div>
        <h3 className="text-lg font-semibold mb-4">Configuração do Webhook</h3>
        
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              URL do Webhook
            </label>
            <div className="flex items-center gap-2">
              <code className="flex-1 bg-gray-50 p-3 rounded-md text-sm">
                {webhookUrl}
              </code>
              <button
                onClick={copiarUrl}
                className="p-2 text-gray-600 hover:text-gray-900"
                title="Copiar URL"
              >
                {copiado ? (
                  <CheckCircle className="w-5 h-5 text-green-600" />
                ) : (
                  <Copy className="w-5 h-5" />
                )}
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Exemplo de Payload
            </label>
            <pre className="bg-gray-50 p-3 rounded-md text-sm overflow-x-auto">
              {JSON.stringify(exemploPayload, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};