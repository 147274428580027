import React from 'react';
import { DollarSign, Trophy, Target, X } from 'lucide-react';
import { useNotificacoes } from '../../contexts/NotificacoesContext';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const iconesPorTipo = {
  venda: DollarSign,
  ranking: Trophy,
  meta: Target
};

const coresPorTipo = {
  venda: 'bg-green-100 text-green-800 border-green-200',
  ranking: 'bg-indigo-100 text-indigo-800 border-indigo-200',
  meta: 'bg-yellow-100 text-yellow-800 border-yellow-200'
};

export const Notificacoes: React.FC = () => {
  const { notificacoes, removerNotificacao } = useNotificacoes();

  if (notificacoes.length === 0) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50 space-y-2 max-w-sm">
      {notificacoes.map((notificacao) => {
        const Icone = iconesPorTipo[notificacao.tipo];
        const cores = coresPorTipo[notificacao.tipo];

        return (
          <div
            key={notificacao.id}
            className={`p-4 rounded-lg border shadow-lg ${cores} animate-slide-in`}
          >
            <div className="flex items-start gap-3">
              <Icone className="w-5 h-5 flex-shrink-0" />
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium">
                  {notificacao.mensagem}
                </p>
                <p className="text-xs mt-1 opacity-75">
                  {format(notificacao.timestamp, "HH:mm", { locale: ptBR })}
                </p>
              </div>
              <button
                onClick={() => removerNotificacao(notificacao.id)}
                className="flex-shrink-0 hover:opacity-75"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};