import React, { useState } from 'react';
import { Users, Plus } from 'lucide-react';
import { useStore } from '../../../store/useStore';
import { TabelaVendedores } from './TabelaVendedores';
import { NovoVendedorModal } from './NovoVendedorModal';
import { Alert } from '../../shared/Alert';
import { Usuario } from '../../../types';

export const GestaoVendedores: React.FC = () => {
  const { usuarios, adicionarUsuario, editarUsuario, removerUsuario } = useStore();
  const [modalAberto, setModalAberto] = useState(false);
  const [vendedorEditando, setVendedorEditando] = useState<Usuario | null>(null);
  const [erro, setErro] = useState<string | null>(null);
  const [sucesso, setSucesso] = useState<string | null>(null);

  // Filtra apenas vendedores
  const vendedores = usuarios.filter(u => u.tipo === 'vendedor');

  const handleSubmit = (formData: any) => {
    try {
      if (vendedorEditando) {
        editarUsuario({
          ...vendedorEditando,
          ...formData
        });
        setSucesso('Vendedor atualizado com sucesso!');
      } else {
        adicionarUsuario({
          ...formData,
          tipo: 'vendedor',
          ativo: true,
          dataCriacao: new Date()
        });
        setSucesso('Vendedor criado com sucesso!');
      }
      setModalAberto(false);
      setVendedorEditando(null);
    } catch (error) {
      setErro(error instanceof Error ? error.message : 'Erro ao salvar vendedor');
    }
  };

  return (
    <div className="p-6">
      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      {sucesso && (
        <Alert 
          tipo="sucesso" 
          mensagem={sucesso} 
          onClose={() => setSucesso(null)} 
        />
      )}

      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <Users className="w-6 h-6 text-indigo-600" />
          <h2 className="text-2xl font-bold">Gestão de Vendedores</h2>
        </div>
        
        <button
          onClick={() => {
            setVendedorEditando(null);
            setModalAberto(true);
          }}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          <Plus className="w-5 h-5" />
          Novo Vendedor
        </button>
      </div>

      <TabelaVendedores
        vendedores={vendedores}
        onEditar={(vendedor) => {
          setVendedorEditando(vendedor);
          setModalAberto(true);
        }}
        onRemover={removerUsuario}
      />

      {modalAberto && (
        <NovoVendedorModal
          vendedor={vendedorEditando}
          onClose={() => {
            setModalAberto(false);
            setVendedorEditando(null);
          }}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};