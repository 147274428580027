import { 
  collection, 
  doc, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc,
  query,
  where,
  Timestamp
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { Usuario, Venda, Meta, Produto } from '../types';
import { logger } from './LogService';

class FirebaseService {
  private static instance: FirebaseService;
  
  private constructor() {}

  static getInstance(): FirebaseService {
    if (!FirebaseService.instance) {
      FirebaseService.instance = new FirebaseService();
    }
    return FirebaseService.instance;
  }

  // Users Collection
  async getUsuarios(): Promise<Usuario[]> {
    try {
      const usuariosRef = collection(db, 'usuarios');
      const snapshot = await getDocs(usuariosRef);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Usuario));
    } catch (error) {
      logger.error('Erro ao buscar usuários', error as Error);
      throw error;
    }
  }

  async addUsuario(usuario: Omit<Usuario, 'id'>): Promise<string> {
    try {
      const usuariosRef = collection(db, 'usuarios');
      const docRef = await addDoc(usuariosRef, {
        ...usuario,
        dataCriacao: Timestamp.now()
      });
      return docRef.id;
    } catch (error) {
      logger.error('Erro ao adicionar usuário', error as Error);
      throw error;
    }
  }

  async updateUsuario(id: string, usuario: Partial<Usuario>): Promise<void> {
    try {
      const usuarioRef = doc(db, 'usuarios', id);
      await updateDoc(usuarioRef, usuario);
    } catch (error) {
      logger.error('Erro ao atualizar usuário', error as Error);
      throw error;
    }
  }

  async deleteUsuario(id: string): Promise<void> {
    try {
      const usuarioRef = doc(db, 'usuarios', id);
      await deleteDoc(usuarioRef);
    } catch (error) {
      logger.error('Erro ao deletar usuário', error as Error);
      throw error;
    }
  }

  // Vendas Collection
  async getVendas(): Promise<Venda[]> {
    try {
      const vendasRef = collection(db, 'vendas');
      const snapshot = await getDocs(vendasRef);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        data: (doc.data().data as Timestamp).toDate()
      } as Venda));
    } catch (error) {
      logger.error('Erro ao buscar vendas', error as Error);
      throw error;
    }
  }

  async getVendasByVendedor(vendedorId: string): Promise<Venda[]> {
    try {
      const vendasRef = collection(db, 'vendas');
      const q = query(vendasRef, where('vendedorId', '==', vendedorId));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        data: (doc.data().data as Timestamp).toDate()
      } as Venda));
    } catch (error) {
      logger.error('Erro ao buscar vendas do vendedor', error as Error);
      throw error;
    }
  }

  async addVenda(venda: Omit<Venda, 'id'>): Promise<string> {
    try {
      const vendasRef = collection(db, 'vendas');
      const docRef = await addDoc(vendasRef, {
        ...venda,
        data: Timestamp.fromDate(new Date(venda.data))
      });
      return docRef.id;
    } catch (error) {
      logger.error('Erro ao adicionar venda', error as Error);
      throw error;
    }
  }

  // Metas Collection
  async getMetas(): Promise<Meta[]> {
    try {
      const metasRef = collection(db, 'metas');
      const snapshot = await getDocs(metasRef);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        dataInicio: (doc.data().dataInicio as Timestamp).toDate(),
        dataFim: (doc.data().dataFim as Timestamp).toDate()
      } as Meta));
    } catch (error) {
      logger.error('Erro ao buscar metas', error as Error);
      throw error;
    }
  }

  async addMeta(meta: Omit<Meta, 'id'>): Promise<string> {
    try {
      const metasRef = collection(db, 'metas');
      const docRef = await addDoc(metasRef, {
        ...meta,
        dataInicio: Timestamp.fromDate(new Date(meta.dataInicio)),
        dataFim: Timestamp.fromDate(new Date(meta.dataFim))
      });
      return docRef.id;
    } catch (error) {
      logger.error('Erro ao adicionar meta', error as Error);
      throw error;
    }
  }

  // Produtos Collection
  async getProdutos(): Promise<Produto[]> {
    try {
      const produtosRef = collection(db, 'produtos');
      const snapshot = await getDocs(produtosRef);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Produto));
    } catch (error) {
      logger.error('Erro ao buscar produtos', error as Error);
      throw error;
    }
  }

  async addProduto(produto: Omit<Produto, 'id'>): Promise<string> {
    try {
      const produtosRef = collection(db, 'produtos');
      const docRef = await addDoc(produtosRef, produto);
      return docRef.id;
    } catch (error) {
      logger.error('Erro ao adicionar produto', error as Error);
      throw error;
    }
  }
}

export const firebaseService = FirebaseService.getInstance();