import { useCallback } from 'react';

type TipoSom = 'venda' | 'ranking' | 'meta';

const sons: Record<TipoSom, HTMLAudioElement | null> = {
  venda: null,
  ranking: null,
  meta: null
};

// Inicializa os sons apenas no cliente
if (typeof window !== 'undefined') {
  sons.venda = new Audio('/sons/caixa-registradora.mp3');
  sons.ranking = new Audio('/sons/ranking.mp3');
  sons.meta = new Audio('/sons/meta-atingida.mp3');
}

export function useSom() {
  const tocarSom = useCallback((tipo: TipoSom) => {
    try {
      const som = sons[tipo];
      if (som) {
        som.currentTime = 0;
        som.volume = 0.5;
        som.play().catch(() => {
          // Ignora erros de reprodução (comum em alguns navegadores)
        });
      }
    } catch (erro) {
      console.warn('Erro ao reproduzir som:', erro);
    }
  }, []);

  return { tocarSom };
}