import React from 'react';

interface BarraProgressoProps {
  progresso: number;
  corBarra?: string;
  altura?: string;
  animada?: boolean;
}

export const BarraProgresso: React.FC<BarraProgressoProps> = ({
  progresso,
  corBarra = 'bg-green-500',
  altura = 'h-2',
  animada = true
}) => {
  const progressoNormalizado = Math.min(Math.max(progresso, 0), 100);

  return (
    <div className={`w-full ${altura} bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden`}>
      <div
        className={`${corBarra} transition-all duration-500 ease-out ${animada ? 'progress-bar-animated' : ''}`}
        style={{ width: `${progressoNormalizado}%` }}
      />
    </div>
  );
};