import React, { useRef } from 'react';
import { Camera, X } from 'lucide-react';
import { Usuario } from '../../types';

interface FotoPerfilFormProps {
  usuario: Usuario;
  onFotoChange: (arquivo: File) => void;
  onRemoverFoto: () => void;
}

export const FotoPerfilForm: React.FC<FotoPerfilFormProps> = ({
  usuario,
  onFotoChange,
  onRemoverFoto
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arquivo = event.target.files?.[0];
    if (arquivo) {
      onFotoChange(arquivo);
    }
  };

  return (
    <div className="flex items-center gap-6">
      <div className="relative">
        {usuario.foto ? (
          <div className="relative">
            <img
              src={usuario.foto}
              alt={usuario.nome}
              className="w-24 h-24 rounded-full object-cover"
            />
            <button
              onClick={onRemoverFoto}
              className="absolute -top-2 -right-2 p-1 bg-red-100 text-red-600 rounded-full hover:bg-red-200"
              title="Remover foto"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ) : (
          <div className="w-24 h-24 rounded-full bg-gray-100 flex items-center justify-center">
            <span className="text-3xl font-semibold text-gray-400">
              {usuario.nome.charAt(0)}
            </span>
          </div>
        )}
      </div>

      <div className="space-y-2">
        <button
          onClick={() => inputFileRef.current?.click()}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 bg-indigo-50 rounded-md hover:bg-indigo-100"
        >
          <Camera className="w-4 h-4" />
          {usuario.foto ? 'Alterar foto' : 'Adicionar foto'}
        </button>
        <p className="text-sm text-gray-500">
          JPG ou PNG, máximo 2MB
        </p>
      </div>

      <input
        ref={inputFileRef}
        type="file"
        accept="image/jpeg,image/png"
        onChange={handleFileChange}
        className="hidden"
      />
    </div>
  );
};