import React, { useState } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, subMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  Legend 
} from 'recharts';
import { Venda } from '../../types';
import { formatarMoeda } from '../../utils/formatacao';

interface GraficoVendasProps {
  vendas: Venda[];
}

export const GraficoVendas: React.FC<GraficoVendasProps> = ({ vendas }) => {
  const [periodoMeses, setPeriodoMeses] = useState(1);
  
  // Calcula o período do gráfico
  const hoje = new Date();
  const inicioMes = startOfMonth(subMonths(hoje, periodoMeses - 1));
  const fimMes = endOfMonth(hoje);
  
  // Gera array com todos os dias do período
  const diasPeriodo = eachDayOfInterval({ start: inicioMes, end: fimMes });
  
  // Processa os dados para o gráfico
  const dados = diasPeriodo.map(dia => {
    const vendasDoDia = vendas.filter(venda => {
      const dataVenda = new Date(venda.data);
      return (
        dataVenda.getDate() === dia.getDate() &&
        dataVenda.getMonth() === dia.getMonth() &&
        dataVenda.getFullYear() === dia.getFullYear()
      );
    });

    return {
      data: format(dia, 'dd/MM', { locale: ptBR }),
      valor: vendasDoDia.reduce((acc, venda) => acc + venda.valor, 0),
      quantidade: vendasDoDia.reduce((acc, venda) => acc + venda.quantidade, 0)
    };
  });

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Histórico de Vendas</h3>
        <select
          value={periodoMeses}
          onChange={(e) => setPeriodoMeses(Number(e.target.value))}
          className="px-3 py-1 border rounded-md text-sm"
        >
          <option value={1}>Último mês</option>
          <option value={3}>Últimos 3 meses</option>
          <option value={6}>Últimos 6 meses</option>
          <option value={12}>Último ano</option>
        </select>
      </div>

      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={dados}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="data" 
              interval={Math.floor(dados.length / 10)}
              angle={-45}
              textAnchor="end"
              height={60}
            />
            <YAxis 
              yAxisId="left"
              tickFormatter={(value) => formatarMoeda(value)}
            />
            <YAxis 
              yAxisId="right" 
              orientation="right"
              tickFormatter={(value) => `${value} un.`}
            />
            <Tooltip 
              formatter={(value: number, name: string) => {
                if (name === 'valor') return [formatarMoeda(value), 'Valor'];
                return [`${value} un.`, 'Quantidade'];
              }}
              labelFormatter={(label) => `Data: ${label}`}
            />
            <Legend />
            <Line 
              yAxisId="left"
              type="monotone" 
              dataKey="valor" 
              name="Valor"
              stroke="#4f46e5" 
              strokeWidth={2}
              dot={false}
              activeDot={{ r: 6 }}
            />
            <Line 
              yAxisId="right"
              type="monotone" 
              dataKey="quantidade" 
              name="Quantidade"
              stroke="#22c55e" 
              strokeWidth={2}
              dot={false}
              activeDot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};