import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Trophy,
  Target,
  ShoppingCart,
  Package,
  LogOut,
  Menu,
  X,
  LayoutGrid,
  Settings,
  Webhook,
  Users
} from 'lucide-react';
import { useStore } from '../../store/useStore';
import { ThemeToggle } from './ThemeToggle';

export function Layout({ children }: { children: React.ReactNode }) {
  const [menuAberto, setMenuAberto] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { usuarioAtual, logout } = useStore();

  // Itens principais do menu
  const menuItems = [
    {
      titulo: 'Dashboard',
      icone: <LayoutDashboard className="w-5 h-5" />,
      rota: '/dashboard',
      permissoes: ['admin', 'supervisor', 'vendedor']
    },
    {
      titulo: 'Ranking',
      icone: <Trophy className="w-5 h-5" />,
      rota: '/ranking',
      permissoes: ['admin', 'supervisor', 'vendedor']
    },
    {
      titulo: 'Produtos',
      icone: <Package className="w-5 h-5" />,
      rota: '/produtos',
      permissoes: ['admin', 'supervisor']
    },
    {
      titulo: 'Vendedores',
      icone: <Users className="w-5 h-5" />,
      rota: '/usuarios',
      permissoes: ['admin', 'supervisor']
    },
    {
      titulo: 'Vendas',
      icone: <ShoppingCart className="w-5 h-5" />,
      rota: '/vendas',
      permissoes: ['admin', 'supervisor']
    },
    {
      titulo: 'Metas',
      icone: <Target className="w-5 h-5" />,
      rota: '/metas',
      permissoes: ['admin', 'supervisor']
    },
    {
      titulo: 'Kanban',
      icone: <LayoutGrid className="w-5 h-5" />,
      rota: '/kanban',
      permissoes: ['admin', 'supervisor', 'vendedor']
    }
  ];

  // Itens do menu de configurações
  const configItems = [
    {
      titulo: 'Webhooks',
      icone: <Webhook className="w-5 h-5" />,
      rota: '/webhooks',
      permissoes: ['admin']
    },
    {
      titulo: 'Configurações',
      icone: <Settings className="w-5 h-5" />,
      rota: '/perfil',
      permissoes: ['admin', 'supervisor', 'vendedor']
    }
  ];

  const itensPermitidosMenu = menuItems.filter(
    item => usuarioAtual && item.permissoes.includes(usuarioAtual.tipo)
  );

  const itensPermitidosConfig = configItems.filter(
    item => usuarioAtual && item.permissoes.includes(usuarioAtual.tipo)
  );

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const renderMenuItem = (item: typeof menuItems[0]) => (
    <li key={item.rota}>
      <button
        onClick={() => {
          navigate(item.rota);
          setMenuAberto(false);
        }}
        className={`
          w-full flex items-center gap-3 px-4 py-2 rounded-md
          ${location.pathname === item.rota
            ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400'
            : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
          }
        `}
      >
        {item.icone}
        <span>{item.titulo}</span>
      </button>
    </li>
  );

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex">
      <aside className={`
        fixed lg:static inset-y-0 left-0 z-50
        transform ${menuAberto ? 'translate-x-0' : '-translate-x-full'}
        lg:translate-x-0 transition-transform duration-200 ease-in-out
        w-64 bg-white dark:bg-gray-800 shadow-lg
      `}>
        <div className="h-full flex flex-col">
          <div className="p-6 border-b dark:border-gray-700">
            <h1 className="text-xl font-bold text-indigo-600 dark:text-indigo-400">
              Sistema de Ranking
            </h1>
          </div>

          <nav className="flex-1 p-4 overflow-y-auto">
            <ul className="space-y-2">
              {itensPermitidosMenu.map(renderMenuItem)}
            </ul>

            {itensPermitidosConfig.length > 0 && (
              <>
                <div className="my-4 border-t dark:border-gray-700" />
                <ul className="space-y-2">
                  {itensPermitidosConfig.map(renderMenuItem)}
                </ul>
              </>
            )}
          </nav>

          <div className="p-4 border-t dark:border-gray-700">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-full bg-indigo-100 dark:bg-indigo-900 flex items-center justify-center">
                  {usuarioAtual?.foto ? (
                    <img
                      src={usuarioAtual.foto}
                      alt={usuarioAtual.nome}
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  ) : (
                    <span className="text-indigo-600 dark:text-indigo-400 font-semibold">
                      {usuarioAtual?.nome.charAt(0)}
                    </span>
                  )}
                </div>
                <div>
                  <p className="font-medium text-gray-900 dark:text-white">
                    {usuarioAtual?.nome}
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400 capitalize">
                    {usuarioAtual?.tipo}
                  </p>
                </div>
              </div>
              <ThemeToggle />
            </div>
            <button
              onClick={handleLogout}
              className="w-full flex items-center gap-2 px-4 py-2 text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/50 rounded-md"
            >
              <LogOut className="w-5 h-5" />
              <span>Sair</span>
            </button>
          </div>
        </div>
      </aside>

      <main className="flex-1 min-h-screen">
        <div className="container mx-auto px-4 py-6 max-w-[1400px]">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
            {children}
          </div>
        </div>
      </main>

      <button
        onClick={() => setMenuAberto(!menuAberto)}
        className="lg:hidden fixed top-4 left-4 z-50 p-2 rounded-md bg-white dark:bg-gray-800 shadow-md"
      >
        {menuAberto ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>

      {menuAberto && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 lg:hidden z-40"
          onClick={() => setMenuAberto(false)}
        />
      )}
    </div>
  );
}