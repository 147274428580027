import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginForm } from '../components/auth/LoginForm';
import { DashboardAdmin } from '../components/admin/DashboardAdmin';
import { DashboardVendedor } from '../components/vendedor/DashboardVendedor';
import { GestaoUsuarios } from '../components/admin/GestaoUsuarios';
import { GestaoVendas } from '../components/admin/vendas/GestaoVendas';
import { GestaoMetas } from '../components/admin/metas/GestaoMetas';
import { GestaoProdutos } from '../components/admin/produtos/GestaoProdutos';
import { GestaoVendedores } from '../components/admin/vendedores/GestaoVendedores';
import { PainelRanking } from '../components/PainelRanking';
import { KanbanBoard } from '../components/kanban/KanbanBoard';
import { WebhookPage } from '../components/admin/webhook/WebhookPage';
import { UserProfileSettings } from '../components/profile/UserProfileSettings';
import { Layout } from '../components/layout/Layout';
import { useStore } from '../store/useStore';

interface RotaPrivadaProps {
  children: React.ReactNode;
  permissoes?: ('admin' | 'supervisor' | 'vendedor')[];
}

const RotaPrivada: React.FC<RotaPrivadaProps> = ({ children, permissoes = [] }) => {
  const usuarioAtual = useStore((state) => state.usuarioAtual);
  
  if (!usuarioAtual) {
    return <Navigate to="/login" replace />;
  }

  if (permissoes.length > 0 && !permissoes.includes(usuarioAtual.tipo)) {
    return <Navigate to="/dashboard" replace />;
  }
  
  return <Layout>{children}</Layout>;
};

export const AppRoutes = () => {
  const usuarioAtual = useStore((state) => state.usuarioAtual);

  return (
    <Routes>
      <Route 
        path="/login" 
        element={usuarioAtual ? <Navigate to="/dashboard" replace /> : <LoginForm />} 
      />
      
      <Route
        path="/dashboard"
        element={
          <RotaPrivada>
            {usuarioAtual?.tipo === 'vendedor' ? (
              <DashboardVendedor />
            ) : (
              <DashboardAdmin />
            )}
          </RotaPrivada>
        }
      />

      <Route
        path="/usuarios"
        element={
          <RotaPrivada permissoes={['admin']}>
            <GestaoUsuarios />
          </RotaPrivada>
        }
      />

      <Route
        path="/produtos"
        element={
          <RotaPrivada permissoes={['admin', 'supervisor']}>
            <GestaoProdutos />
          </RotaPrivada>
        }
      />

      <Route
        path="/vendedores"
        element={
          <RotaPrivada permissoes={['admin', 'supervisor']}>
            <GestaoVendedores />
          </RotaPrivada>
        }
      />

      <Route
        path="/vendas"
        element={
          <RotaPrivada permissoes={['admin', 'supervisor']}>
            <GestaoVendas />
          </RotaPrivada>
        }
      />

      <Route
        path="/metas"
        element={
          <RotaPrivada permissoes={['admin', 'supervisor']}>
            <GestaoMetas />
          </RotaPrivada>
        }
      />

      <Route
        path="/ranking"
        element={
          <RotaPrivada>
            <PainelRanking />
          </RotaPrivada>
        }
      />

      <Route
        path="/kanban"
        element={
          <RotaPrivada>
            <KanbanBoard />
          </RotaPrivada>
        }
      />

      <Route
        path="/webhooks"
        element={
          <RotaPrivada permissoes={['admin']}>
            <WebhookPage />
          </RotaPrivada>
        }
      />

      <Route
        path="/perfil"
        element={
          <RotaPrivada>
            <UserProfileSettings />
          </RotaPrivada>
        }
      />

      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};