import React from 'react';
import { DollarSign, Target, TrendingUp } from 'lucide-react';
import { useStore } from '../../store/useStore';
import { CardMetrica } from '../admin/dashboard/CardMetrica';
import { GraficoVendas } from './GraficoVendas';
import { ListaMetasVendedor } from './ListaMetasVendedor';
import { RelatorioDesempenho } from './RelatorioDesempenho';
import { PerfilVendedor } from './PerfilVendedor';
import { RegistrarVenda } from './RegistrarVenda';
import { calcularTotalVendas } from '../../utils/vendas';
import { calcularProgressoMeta } from '../../utils/metas';

export const DashboardVendedor: React.FC = () => {
  const { usuarioAtual, vendas, metas } = useStore();

  if (!usuarioAtual) return null;

  const vendasVendedor = vendas.filter(v => v.vendedorId === usuarioAtual.id);
  const metasVendedor = metas.filter(m => m.vendedorId === usuarioAtual.id);
  
  const totalVendas = calcularTotalVendas(vendas, usuarioAtual.id);
  const totalQuantidade = vendasVendedor.reduce((acc, v) => acc + v.quantidade, 0);
  
  const metaAtual = metasVendedor.find(m => {
    const hoje = new Date();
    return hoje >= new Date(m.dataInicio) && hoje <= new Date(m.dataFim);
  });

  const progressoMeta = metaAtual 
    ? calcularProgressoMeta(metaAtual, vendas)
    : null;

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Meu Dashboard</h2>
        <RegistrarVenda />
      </div>
      
      <PerfilVendedor />
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <CardMetrica
          titulo="Total em Vendas"
          valor={`R$ ${totalVendas.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`}
          Icone={DollarSign}
          corIcone="text-green-600"
        />
        
        <CardMetrica
          titulo="Quantidade Vendida"
          valor={totalQuantidade}
          Icone={TrendingUp}
          corIcone="text-blue-600"
        />
        
        <CardMetrica
          titulo="Progresso da Meta"
          valor={progressoMeta ? `${progressoMeta.progressoValor.toFixed(1)}%` : 'Sem meta atual'}
          Icone={Target}
          corIcone="text-indigo-600"
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <GraficoVendas vendas={vendasVendedor} />
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4">Minhas Metas</h3>
          <ListaMetasVendedor metas={metasVendedor} vendas={vendas} />
        </div>
      </div>

      <RelatorioDesempenho vendas={vendasVendedor} metas={metasVendedor} />
    </div>
  );
};