import React, { useState } from 'react';
import { useStore } from '../../../store/useStore';
import { Meta, TipoMeta } from '../../../types';
import { Alert } from '../../shared/Alert';

interface FormularioMetaProps {
  onSubmit: (e: React.FormEvent) => void;
  onCancel: () => void;
  metaEditando: Meta | null;
}

export const FormularioMeta: React.FC<FormularioMetaProps> = ({
  onSubmit,
  onCancel,
  metaEditando
}) => {
  const { usuarios } = useStore();
  const vendedores = usuarios.filter(u => u.tipo === 'vendedor');
  const [tipoMeta, setTipoMeta] = useState<TipoMeta>(metaEditando?.tipo || 'valor');
  const [erro, setErro] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!tipoMeta) {
      setErro('Selecione o tipo de meta');
      return;
    }

    onSubmit(e);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Vendedor
        </label>
        <select
          name="vendedorId"
          defaultValue={metaEditando?.vendedorId || ''}
          className="w-full px-3 py-2 border rounded-md"
          required
        >
          <option value="">Selecione um vendedor</option>
          {vendedores.map((vendedor) => (
            <option key={vendedor.id} value={vendedor.id}>
              {vendedor.nome}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Tipo de Meta
        </label>
        <select
          name="tipo"
          value={tipoMeta}
          onChange={(e) => setTipoMeta(e.target.value as TipoMeta)}
          className="w-full px-3 py-2 border rounded-md"
          required
        >
          <option value="valor">Meta por Valor</option>
          <option value="quantidade">Meta por Quantidade</option>
        </select>
      </div>

      {tipoMeta === 'valor' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Meta de Valor (R$)
          </label>
          <input
            type="number"
            name="valorMeta"
            defaultValue={metaEditando?.valorMeta || ''}
            className="w-full px-3 py-2 border rounded-md"
            min="0"
            step="0.01"
            required
          />
        </div>
      )}

      {tipoMeta === 'quantidade' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Meta de Quantidade
          </label>
          <input
            type="number"
            name="quantidadeMeta"
            defaultValue={metaEditando?.quantidadeMeta || ''}
            className="w-full px-3 py-2 border rounded-md"
            min="0"
            required
          />
        </div>
      )}

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Data Início
          </label>
          <input
            type="date"
            name="dataInicio"
            defaultValue={metaEditando?.dataInicio || ''}
            className="w-full px-3 py-2 border rounded-md"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Data Fim
          </label>
          <input
            type="date"
            name="dataFim"
            defaultValue={metaEditando?.dataFim || ''}
            className="w-full px-3 py-2 border rounded-md"
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Bonificação (R$)
        </label>
        <input
          type="number"
          name="bonificacao"
          defaultValue={metaEditando?.bonificacao || ''}
          className="w-full px-3 py-2 border rounded-md"
          min="0"
          step="0.01"
          required
        />
      </div>

      <div className="flex justify-end gap-4 mt-6">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md"
        >
          {metaEditando ? 'Salvar Alterações' : 'Criar Meta'}
        </button>
      </div>
    </form>
  );
};