import React from 'react';
import { Plus, Settings, Trash2, ExternalLink } from 'lucide-react';
import { Webhook } from '../../../types/webhook';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface ListaWebhooksProps {
  webhooks: Webhook[];
  onAdicionar: () => void;
  onEditar: (webhook: Webhook) => void;
  onRemover: (id: string) => void;
}

export const ListaWebhooks: React.FC<ListaWebhooksProps> = ({
  webhooks,
  onAdicionar,
  onEditar,
  onRemover
}) => {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Webhooks</h2>
        <button
          onClick={onAdicionar}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          <Plus className="w-5 h-5" />
          Novo Webhook
        </button>
      </div>

      {webhooks.length === 0 ? (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <p className="text-gray-500 mb-4">Nenhum webhook configurado</p>
          <button
            onClick={onAdicionar}
            className="text-indigo-600 hover:text-indigo-800 font-medium"
          >
            Clique aqui para adicionar seu primeiro webhook
          </button>
        </div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {webhooks.map(webhook => (
            <div key={webhook.id} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="font-semibold text-lg">{webhook.nome}</h3>
                  <p className="text-sm text-gray-500">
                    Criado em {format(new Date(webhook.dataCriacao), "dd/MM/yyyy", { locale: ptBR })}
                  </p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => onEditar(webhook)}
                    className="p-2 text-gray-600 hover:text-indigo-600 rounded-full hover:bg-gray-100"
                    title="Configurar"
                  >
                    <Settings className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => onRemover(webhook.id)}
                    className="p-2 text-gray-600 hover:text-red-600 rounded-full hover:bg-gray-100"
                    title="Remover"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>

              <div className="space-y-3">
                <div className="flex items-center gap-2 text-sm">
                  <ExternalLink className="w-4 h-4 text-gray-500" />
                  <span className="text-gray-600 truncate">{webhook.url}</span>
                </div>

                <div className="flex gap-2">
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    webhook.modo === 'test' 
                      ? 'bg-yellow-100 text-yellow-800' 
                      : 'bg-green-100 text-green-800'
                  }`}>
                    {webhook.modo === 'test' ? 'Teste' : 'Produção'}
                  </span>
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    webhook.ativo
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {webhook.ativo ? 'Ativo' : 'Inativo'}
                  </span>
                </div>

                <div className="text-sm text-gray-600">
                  <p>Total de requisições: {
                    webhook.stats[webhook.modo].totalRequests
                  }</p>
                  <p>Taxa de sucesso: {
                    webhook.stats[webhook.modo].totalRequests > 0
                      ? ((webhook.stats[webhook.modo].successCount / webhook.stats[webhook.modo].totalRequests) * 100).toFixed(1)
                      : '0'
                  }%</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};