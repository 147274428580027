import { useEffect, useState } from 'react';
import { firebaseService } from '../services/FirebaseService';
import { Usuario, Venda, Meta, Produto } from '../types';
import { useStore } from '../store/useStore';

export function useFirebase() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { 
    setUsuarios, 
    setVendas, 
    setMetas, 
    setProdutos 
  } = useStore();

  // Load initial data
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        
        // Load all data in parallel
        const [usuarios, vendas, metas, produtos] = await Promise.all([
          firebaseService.getUsuarios(),
          firebaseService.getVendas(),
          firebaseService.getMetas(),
          firebaseService.getProdutos()
        ]);

        // Update store
        setUsuarios(usuarios);
        setVendas(vendas);
        setMetas(metas);
        setProdutos(produtos);

        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  return { loading, error };
}