import React, { useState } from 'react';
import { FileText } from 'lucide-react';
import { useStore } from '../../../store/useStore';
import { Venda } from '../../../types';
import { exportarVendasCSV, downloadCSV } from '../../../utils/exportacao';

interface ExportarVendasProps {
  vendasFiltradas: Venda[];
}

export const ExportarVendas: React.FC<ExportarVendasProps> = ({ vendasFiltradas }) => {
  const { usuarios } = useStore();
  const [exportando, setExportando] = useState(false);

  const handleExportar = () => {
    setExportando(true);
    try {
      const conteudoCSV = exportarVendasCSV(vendasFiltradas, usuarios);
      const dataAtual = new Date().toISOString().split('T')[0];
      downloadCSV(conteudoCSV, `vendas_${dataAtual}.csv`);
    } finally {
      setExportando(false);
    }
  };

  return (
    <button
      onClick={handleExportar}
      disabled={exportando}
      className={`
        bg-gray-100 text-gray-700 px-4 py-2 rounded-md
        hover:bg-gray-200 flex items-center gap-2
        ${exportando ? 'opacity-50 cursor-not-allowed' : ''}
      `}
    >
      {exportando ? (
        <>
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-700" />
          <span>Exportando...</span>
        </>
      ) : (
        <>
          <FileText className="w-5 h-5" />
          <span>Exportar CSV</span>
        </>
      )}
    </button>
  );
};