import React, { useState, useRef } from 'react';
import { Camera, X } from 'lucide-react';
import { useStore } from '../../store/useStore';
import { Alert } from '../shared/Alert';

export const PerfilVendedor: React.FC = () => {
  const { usuarioAtual, atualizarUsuario } = useStore();
  const [erro, setErro] = useState<string | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  if (!usuarioAtual) return null;

  const handleFotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const arquivo = event.target.files?.[0];
    if (!arquivo) return;

    // Validar tipo de arquivo
    if (!arquivo.type.startsWith('image/')) {
      setErro('Por favor, selecione uma imagem válida');
      return;
    }

    // Validar tamanho (máximo 2MB)
    if (arquivo.size > 2 * 1024 * 1024) {
      setErro('A imagem deve ter no máximo 2MB');
      return;
    }

    try {
      // Converter para Base64
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          atualizarUsuario({
            ...usuarioAtual,
            foto: reader.result
          });
        }
      };
      reader.readAsDataURL(arquivo);
    } catch (error) {
      setErro('Erro ao processar a imagem');
    }
  };

  const removerFoto = () => {
    atualizarUsuario({
      ...usuarioAtual,
      foto: undefined
    });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold mb-4">Foto do Perfil</h3>

      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      <div className="flex items-center gap-6">
        <div className="relative">
          {usuarioAtual.foto ? (
            <div className="relative">
              <img
                src={usuarioAtual.foto}
                alt={usuarioAtual.nome}
                className="w-24 h-24 rounded-full object-cover"
              />
              <button
                onClick={removerFoto}
                className="absolute -top-2 -right-2 p-1 bg-red-100 text-red-600 rounded-full hover:bg-red-200"
                title="Remover foto"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-100 flex items-center justify-center">
              <span className="text-3xl font-semibold text-gray-400">
                {usuarioAtual.nome.charAt(0)}
              </span>
            </div>
          )}
        </div>

        <div className="space-y-2">
          <button
            onClick={() => inputFileRef.current?.click()}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 bg-indigo-50 rounded-md hover:bg-indigo-100"
          >
            <Camera className="w-4 h-4" />
            {usuarioAtual.foto ? 'Alterar foto' : 'Adicionar foto'}
          </button>
          <p className="text-sm text-gray-500">
            JPG ou PNG, máximo 2MB
          </p>
        </div>

        <input
          ref={inputFileRef}
          type="file"
          accept="image/jpeg,image/png"
          onChange={handleFotoChange}
          className="hidden"
        />
      </div>
    </div>
  );
};