import React from 'react';
import { Edit, Trash2, ToggleLeft, ToggleRight, Package } from 'lucide-react';
import { Produto } from '../../../types';
import { useStore } from '../../../store/useStore';

interface TabelaProdutosProps {
  produtos: Produto[];
  onEditar: (produto: Produto) => void;
  onRemover: (id: string) => void;
  onAtivarDesativar: (id: string, ativo: boolean) => void;
}

export const TabelaProdutos: React.FC<TabelaProdutosProps> = ({
  produtos,
  onEditar,
  onRemover,
  onAtivarDesativar
}) => {
  const todosOsProdutos = useStore(state => state.produtos);

  const renderItensKit = (produto: Produto) => {
    if (produto.tipo !== 'kit' || !produto.itensKit) return null;

    return (
      <div className="mt-1 text-sm text-gray-500">
        <p className="font-medium">Itens do Kit:</p>
        <ul className="list-disc list-inside">
          {produto.itensKit.map((item, index) => {
            const produtoItem = todosOsProdutos.find(p => p.id === item.produtoId);
            return (
              <li key={index}>
                {produtoItem?.nome} (x{item.quantidade})
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Código
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Nome
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tipo
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Preço
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Unidade
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Ações
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {produtos.map((produto) => (
            <tr key={produto.id} className={!produto.ativo ? 'bg-gray-50' : undefined}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {produto.codigo}
              </td>
              <td className="px-6 py-4">
                <div>
                  <div className="text-sm font-medium text-gray-900">
                    {produto.nome}
                  </div>
                  {produto.descricao && (
                    <div className="text-sm text-gray-500">
                      {produto.descricao}
                    </div>
                  )}
                  {renderItensKit(produto)}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  produto.tipo === 'kit' 
                    ? 'bg-purple-100 text-purple-800'
                    : 'bg-blue-100 text-blue-800'
                }`}>
                  <Package className="w-3 h-3" />
                  {produto.tipo === 'kit' ? 'Kit' : 'Produto'}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {produto.preco.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {produto.unidade}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  onClick={() => onAtivarDesativar(produto.id, !produto.ativo)}
                  className={`inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    produto.ativo
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  {produto.ativo ? (
                    <>
                      <ToggleRight className="w-4 h-4" />
                      Ativo
                    </>
                  ) : (
                    <>
                      <ToggleLeft className="w-4 h-4" />
                      Inativo
                    </>
                  )}
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button
                  onClick={() => onEditar(produto)}
                  className="text-indigo-600 hover:text-indigo-900 mr-3"
                >
                  <Edit className="w-5 h-5" />
                </button>
                <button
                  onClick={() => onRemover(produto.id)}
                  className="text-red-600 hover:text-red-900"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};