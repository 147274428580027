import React, { useState } from 'react';
import { Trophy, Maximize2, Minimize2 } from 'lucide-react';
import { useStore } from '../store/useStore';
import { ordenarVendedoresPorVendas } from '../utils/vendas';
import { calcularProgressoMeta } from '../utils/metas';
import { LayoutSelector, LayoutType } from './ranking/LayoutSelector';
import { PadraoLayout } from './ranking/layouts/PadraoLayout';
import { CompactoLayout } from './ranking/layouts/CompactoLayout';
import { ListaLayout } from './ranking/layouts/ListaLayout';
import { GradeLayout } from './ranking/layouts/GradeLayout';

export const PainelRanking: React.FC = () => {
  const [qtdDestaque, setQtdDestaque] = useState(3);
  const [layout, setLayout] = useState<LayoutType>('padrao');
  const [telaCheia, setTelaCheia] = useState(false);
  const vendas = useStore((state) => state.vendas);
  const usuarios = useStore((state) => state.usuarios);
  const metas = useStore((state) => state.metas);

  const rankingVendedores = ordenarVendedoresPorVendas(usuarios, vendas);

  const getProgressoMeta = (vendedorId: string) => {
    const metaAtiva = metas.find(meta => {
      const hoje = new Date();
      return meta.vendedorId === vendedorId &&
             hoje >= new Date(meta.dataInicio) &&
             hoje <= new Date(meta.dataFim);
    });

    if (!metaAtiva) return null;
    return calcularProgressoMeta(metaAtiva, vendas);
  };

  const renderVendedorDestaque = (vendedor: typeof rankingVendedores[0], posicao: number) => {
    const progresso = getProgressoMeta(vendedor.id);
    const animationClass = posicao === 1 ? 'animate-float-1' : posicao === 2 ? 'animate-float-2' : 'animate-float-3';
    const scale = posicao === 1 ? 'scale-125' : 'scale-100';
    
    return (
      <div key={vendedor.id} className={`flex flex-col items-center ${animationClass}`}>
        <div className={`relative ${scale}`}>
          <div className={`w-32 h-32 rounded-lg transform ${
            posicao === 1 ? 'bg-gradient-to-b from-yellow-400 to-yellow-600' :
            posicao === 2 ? 'bg-gradient-to-b from-gray-300 to-gray-500' :
            'bg-gradient-to-b from-amber-600 to-amber-800'
          } absolute -left-2 -top-2 rotate-3`} />
          
          <div className={`w-32 h-32 bg-white rounded-lg relative z-10 p-4 flex flex-col items-center justify-center shadow-xl`}>
            {posicao === 1 && <Trophy className="w-8 h-8 text-yellow-500 mb-2" />}
            
            {vendedor.foto ? (
              <img 
                src={vendedor.foto} 
                alt={vendedor.nome}
                className={`w-16 h-16 rounded-full border-4 ${
                  posicao === 1 ? 'border-yellow-300' :
                  posicao === 2 ? 'border-gray-300' :
                  'border-amber-500'
                }`}
              />
            ) : (
              <div className={`w-16 h-16 rounded-full flex items-center justify-center border-4 ${
                posicao === 1 ? 'bg-yellow-100 border-yellow-300 text-yellow-800' :
                posicao === 2 ? 'bg-gray-100 border-gray-300 text-gray-800' :
                'bg-amber-100 border-amber-500 text-amber-800'
              }`}>
                <span className="text-2xl font-bold">{vendedor.nome.charAt(0)}</span>
              </div>
            )}
            
            <p className="mt-2 font-bold text-sm text-center text-shadow text-gray-800">
              {vendedor.nome}
            </p>
            <p className={`text-sm font-semibold ${
              posicao === 1 ? 'text-yellow-600' :
              posicao === 2 ? 'text-gray-600' :
              'text-amber-600'
            }`}>
              R$ {vendedor.totalVendas.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderVendedorLista = (vendedor: typeof rankingVendedores[0], posicao: number) => {
    const progresso = getProgressoMeta(vendedor.id);
    
    return (
      <div 
        key={vendedor.id}
        className="flex items-center gap-4 bg-white/10 p-4 rounded-lg backdrop-blur-sm"
      >
        <span className="text-xl font-bold text-white/90">
          {posicao}º
        </span>
        
        {vendedor.foto ? (
          <img 
            src={vendedor.foto} 
            alt={vendedor.nome}
            className="w-12 h-12 rounded-full border-2 border-white/20"
          />
        ) : (
          <div className="w-12 h-12 rounded-full bg-white/20 flex items-center justify-center">
            <span className="text-xl font-bold text-white">
              {vendedor.nome.charAt(0)}
            </span>
          </div>
        )}
        
        <div className="flex-1">
          <p className="font-medium text-white">{vendedor.nome}</p>
          <p className="text-white/80">
            R$ {vendedor.totalVendas.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </p>
          
          {progresso && (
            <div className="mt-2">
              <div className="w-full h-2 bg-black/20 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-white/40 transition-all duration-500"
                  style={{ width: `${progresso.progressoValor}%` }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderLayout = () => {
    const props = {
      vendedores: rankingVendedores,
      getProgressoMeta
    };

    switch (layout) {
      case 'padrao':
        return <PadraoLayout {...props} />;
      case 'compacto':
        return <CompactoLayout {...props} />;
      case 'lista':
        return <ListaLayout {...props} />;
      case 'grade':
        return <GradeLayout {...props} />;
      case 'gamificado':
        return (
          <>
            <div className="flex justify-center items-end gap-6 mb-12">
              {rankingVendedores.slice(0, qtdDestaque).map((vendedor, index) => 
                renderVendedorDestaque(vendedor, index + 1)
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {rankingVendedores.slice(qtdDestaque).map((vendedor, index) => 
                renderVendedorLista(vendedor, index + qtdDestaque + 1)
              )}
            </div>
          </>
        );
    }
  };

  return (
    <div 
      className={`min-h-screen transition-colors duration-500 ${
        layout === 'gamificado' 
          ? 'bg-gradient-to-br from-indigo-900 via-purple-900 to-purple-800' 
          : 'bg-white'
      } ${telaCheia ? 'fixed inset-0 z-50' : ''}`}
    >
      <div className="max-w-7xl mx-auto p-6">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center gap-4">
            <Trophy className="w-8 h-8 text-yellow-400" />
            <h1 className={`text-2xl font-bold ${layout === 'gamificado' ? 'text-white' : 'text-gray-900'}`}>
              Ranking de Vendas
            </h1>
          </div>
          
          <div className="flex items-center gap-4">
            <LayoutSelector layoutAtual={layout} onLayoutChange={setLayout} />
            
            <select
              value={qtdDestaque}
              onChange={(e) => setQtdDestaque(Number(e.target.value))}
              className="px-3 py-2 bg-white rounded-lg border shadow-sm"
            >
              <option value={3}>Top 3</option>
              <option value={5}>Top 5</option>
              <option value={10}>Top 10</option>
            </select>

            <button
              onClick={() => setTelaCheia(!telaCheia)}
              className={`p-2 rounded-lg ${
                layout === 'gamificado' ? 'text-white hover:bg-white/10' : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              {telaCheia ? <Minimize2 className="w-5 h-5" /> : <Maximize2 className="w-5 h-5" />}
            </button>
          </div>
        </div>

        {renderLayout()}
      </div>
    </div>
  );
};