/**
 * Utilitários para formatação de valores e datas
 */

/**
 * Formata um valor monetário para o formato brasileiro
 * @param valor Valor a ser formatado
 * @returns String formatada (ex: R$ 1.234,56)
 */
export const formatarMoeda = (valor: number): string => {
  return valor.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
};

/**
 * Formata uma porcentagem com uma casa decimal
 * @param valor Valor decimal (ex: 0.7856)
 * @returns String formatada (ex: 78,6%)
 */
export const formatarPorcentagem = (valor: number): string => {
  return `${(valor * 100).toFixed(1).replace('.', ',')}%`;
};