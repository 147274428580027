import { Venda, Usuario, Meta } from '../types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

/**
 * Exporta os dados de vendas para formato CSV
 */
export const exportarVendasCSV = (vendas: Venda[], usuarios: Usuario[]): string => {
  // Cabeçalho do arquivo CSV
  const cabecalho = ['Data', 'Vendedor', 'Valor', 'Quantidade', 'Origem'];
  
  // Converte cada venda em uma linha do CSV
  const linhas = vendas.map(venda => {
    const vendedor = usuarios.find(u => u.id === venda.vendedorId);
    return [
      format(new Date(venda.data), 'dd/MM/yyyy HH:mm', { locale: ptBR }),
      vendedor?.nome || 'Vendedor não encontrado',
      venda.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
      venda.quantidade,
      venda.origem === 'manual' ? 'Manual' : 'Webhook'
    ];
  });

  // Junta cabeçalho e linhas e converte para string CSV
  return [cabecalho, ...linhas]
    .map(linha => linha.join(','))
    .join('\n');
};

/**
 * Exporta os dados de metas para formato CSV
 */
export const exportarMetasCSV = (metas: Meta[], usuarios: Usuario[], vendas: Venda[]): string => {
  const cabecalho = [
    'Vendedor',
    'Valor Meta',
    'Quantidade Meta',
    'Data Início',
    'Data Fim',
    'Bonificação',
    'Progresso Valor',
    'Progresso Quantidade',
    'Status'
  ];

  const linhas = metas.map(meta => {
    const vendedor = usuarios.find(u => u.id === meta.vendedorId);
    const vendasPeriodo = vendas.filter(venda => {
      const dataVenda = new Date(venda.data);
      return (
        venda.vendedorId === meta.vendedorId &&
        dataVenda >= new Date(meta.dataInicio) &&
        dataVenda <= new Date(meta.dataFim)
      );
    });

    const valorAtingido = vendasPeriodo.reduce((acc, venda) => acc + venda.valor, 0);
    const quantidadeAtingida = vendasPeriodo.reduce((acc, venda) => acc + venda.quantidade, 0);
    const progressoValor = (valorAtingido / meta.valorMeta) * 100;
    const progressoQuantidade = (quantidadeAtingida / meta.quantidadeMeta) * 100;
    const metaBatida = progressoValor >= 100 && progressoQuantidade >= 100;

    return [
      vendedor?.nome || 'Vendedor não encontrado',
      meta.valorMeta.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
      meta.quantidadeMeta,
      format(new Date(meta.dataInicio), 'dd/MM/yyyy', { locale: ptBR }),
      format(new Date(meta.dataFim), 'dd/MM/yyyy', { locale: ptBR }),
      meta.bonificacao?.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) || 'N/A',
      `${progressoValor.toFixed(1)}%`,
      `${progressoQuantidade.toFixed(1)}%`,
      metaBatida ? 'Concluída' : 'Em andamento'
    ];
  });

  return [cabecalho, ...linhas]
    .map(linha => linha.join(','))
    .join('\n');
};

/**
 * Realiza o download do arquivo CSV
 */
export const downloadCSV = (conteudo: string, nomeArquivo: string) => {
  const blob = new Blob([conteudo], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  
  link.setAttribute('href', url);
  link.setAttribute('download', nomeArquivo);
  link.style.visibility = 'hidden';
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};