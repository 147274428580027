import React, { useState } from 'react';
import { Clock, User, Tag, MoreVertical } from 'lucide-react';
import { KanbanCard } from '../../types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface KanbanCardProps {
  card: KanbanCard;
  onEdit?: (cardAtualizado: KanbanCard) => void;
}

export const KanbanCardComponent: React.FC<KanbanCardProps> = ({ card, onEdit }) => {
  const [modalAberto, setModalAberto] = useState(false);

  const getPrioridadeStyle = (prioridade: string) => {
    switch (prioridade) {
      case 'alta':
        return 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300';
      case 'media':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300';
      case 'baixa':
        return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300';
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
      <div className="flex justify-between items-start mb-2">
        <h4 className="font-medium text-gray-900 dark:text-white">{card.titulo}</h4>
        <div className="flex items-center gap-2">
          <span className={`text-xs px-2 py-1 rounded-full ${getPrioridadeStyle(card.prioridade)}`}>
            {card.prioridade}
          </span>
          <button 
            onClick={() => setModalAberto(true)}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400"
          >
            <MoreVertical className="w-4 h-4" />
          </button>
        </div>
      </div>

      {card.descricao && (
        <p className="text-sm text-gray-600 dark:text-gray-300 mb-3">
          {card.descricao}
        </p>
      )}

      {card.cliente && (
        <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 mb-2">
          <User className="w-4 h-4" />
          <span>{card.cliente}</span>
        </div>
      )}

      {card.prazo && (
        <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
          <Clock className="w-4 h-4" />
          <span>{format(new Date(card.prazo), "dd 'de' MMMM", { locale: ptBR })}</span>
        </div>
      )}

      {card.etiquetas && card.etiquetas.length > 0 && (
        <div className="flex flex-wrap gap-1 mt-2">
          {card.etiquetas.map((etiqueta, index) => (
            <span
              key={index}
              className="flex items-center gap-1 text-xs px-2 py-1 bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 rounded-full"
            >
              <Tag className="w-3 h-3" />
              {etiqueta}
            </span>
          ))}
        </div>
      )}

      {modalAberto && (
        <EditarCardModal
          card={card}
          onClose={() => setModalAberto(false)}
          onSave={(cardAtualizado) => {
            if (onEdit) {
              onEdit(cardAtualizado);
            }
            setModalAberto(false);
          }}
        />
      )}
    </div>
  );
};

interface EditarCardModalProps {
  card: KanbanCard;
  onClose: () => void;
  onSave: (card: KanbanCard) => void;
}

const EditarCardModal: React.FC<EditarCardModalProps> = ({ card, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    titulo: card.titulo,
    cliente: card.cliente || '',
    descricao: card.descricao || '',
    prazo: card.prazo ? new Date(card.prazo).toISOString().split('T')[0] : '',
    prioridade: card.prioridade || 'media'
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({
      ...card,
      ...formData,
      prazo: formData.prazo ? new Date(formData.prazo) : undefined
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
        <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
          {card.titulo ? 'Editar Card' : 'Novo Card'}
        </h3>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Título
            </label>
            <input
              type="text"
              value={formData.titulo}
              onChange={(e) => setFormData({ ...formData, titulo: e.target.value })}
              className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Cliente
            </label>
            <input
              type="text"
              value={formData.cliente}
              onChange={(e) => setFormData({ ...formData, cliente: e.target.value })}
              className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Descrição
            </label>
            <textarea
              value={formData.descricao}
              onChange={(e) => setFormData({ ...formData, descricao: e.target.value })}
              className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              rows={3}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Data de Retorno
            </label>
            <input
              type="date"
              value={formData.prazo}
              onChange={(e) => setFormData({ ...formData, prazo: e.target.value })}
              className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Prioridade
            </label>
            <select
              value={formData.prioridade}
              onChange={(e) => setFormData({ ...formData, prioridade: e.target.value })}
              className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              <option value="baixa">Baixa</option>
              <option value="media">Média</option>
              <option value="alta">Alta</option>
            </select>
          </div>

          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};