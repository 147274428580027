import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NotificacoesProvider } from './contexts/NotificacoesContext';
import { Notificacoes } from './components/shared/Notificacoes';
import { AppRoutes } from './routes';
import { useFirebase } from './hooks/useFirebase';
import { Loading } from './components/shared/Loading';

const App = () => {
  const { loading, error } = useFirebase();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-red-600 mb-2">Erro</h1>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <NotificacoesProvider>
        <AppRoutes />
        <Notificacoes />
      </NotificacoesProvider>
    </BrowserRouter>
  );
};

export default App;