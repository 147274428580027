import { Meta, Venda } from '../types';
import { isWithinInterval } from 'date-fns';

export const calcularProgressoMeta = (meta: Meta, vendas: Venda[]) => {
  const vendasPeriodo = vendas.filter(venda => 
    venda.vendedorId === meta.vendedorId &&
    isWithinInterval(new Date(venda.data), {
      start: new Date(meta.dataInicio),
      end: new Date(meta.dataFim)
    })
  );

  const valorAtingido = vendasPeriodo.reduce((acc, venda) => acc + venda.valor, 0);
  const quantidadeAtingida = vendasPeriodo.reduce((acc, venda) => acc + venda.quantidade, 0);

  const progressoValor = meta.tipo === 'valor' && meta.valorMeta 
    ? (valorAtingido / meta.valorMeta) * 100 
    : 0;

  const progressoQuantidade = meta.tipo === 'quantidade' && meta.quantidadeMeta 
    ? (quantidadeAtingida / meta.quantidadeMeta) * 100 
    : 0;

  const metaBatida = meta.tipo === 'valor' 
    ? progressoValor >= 100 
    : progressoQuantidade >= 100;

  return {
    valorAtingido,
    quantidadeAtingida,
    progressoValor: Math.min(progressoValor, 100),
    progressoQuantidade: Math.min(progressoQuantidade, 100),
    metaBatida
  };
};