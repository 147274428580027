import React from 'react';
import { Trophy, Medal, TrendingUp } from 'lucide-react';
import { BarraProgresso } from '../../shared/BarraProgresso';
import { Usuario } from '../../../types';

interface ListaLayoutProps {
  vendedores: (Usuario & { totalVendas: number })[];
  getProgressoMeta: (vendedorId: string) => any;
}

export const ListaLayout: React.FC<ListaLayoutProps> = ({ vendedores, getProgressoMeta }) => {
  return (
    <div className="space-y-3">
      {vendedores.map((vendedor, index) => {
        const progresso = getProgressoMeta(vendedor.id);
        const posicao = index + 1;

        return (
          <div 
            key={vendedor.id}
            className="bg-white rounded-lg shadow-sm p-4 flex items-center gap-4 transition-colors hover:bg-gray-50"
          >
            <div className="flex items-center gap-2 w-20">
              {posicao === 1 && <Trophy className="w-5 h-5 text-yellow-400" />}
              {posicao === 2 && <Medal className="w-5 h-5 text-gray-400" />}
              {posicao === 3 && <Medal className="w-5 h-5 text-amber-600" />}
              {posicao > 3 && <TrendingUp className="w-5 h-5 text-gray-400" />}
              <span className="text-lg font-bold text-gray-700">{posicao}º</span>
            </div>

            <div className="flex-shrink-0">
              {vendedor.foto ? (
                <img 
                  src={vendedor.foto} 
                  alt={vendedor.nome}
                  className="w-12 h-12 rounded-full object-cover"
                />
              ) : (
                <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center">
                  <span className="text-xl font-bold text-gray-500">
                    {vendedor.nome.charAt(0)}
                  </span>
                </div>
              )}
            </div>

            <div className="flex-grow min-w-0">
              <h3 className="text-base font-medium text-gray-800 truncate">
                {vendedor.nome}
              </h3>
              <p className="text-sm text-indigo-600 font-medium">
                R$ {vendedor.totalVendas.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
              </p>
            </div>

            {progresso && (
              <div className="w-48">
                <div className="flex justify-between text-xs text-gray-600 mb-1">
                  <span>Meta</span>
                  <span>{progresso.progressoValor.toFixed(1)}%</span>
                </div>
                <BarraProgresso 
                  progresso={progresso.progressoValor}
                  corBarra="bg-indigo-600"
                  altura="h-1.5"
                  animada={true}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};