import React from 'react';
import { Trophy, Medal } from 'lucide-react';
import { BarraProgresso } from '../../shared/BarraProgresso';
import { Usuario } from '../../../types';

interface PadraoLayoutProps {
  vendedores: (Usuario & { totalVendas: number })[];
  getProgressoMeta: (vendedorId: string) => any;
}

export const PadraoLayout: React.FC<PadraoLayoutProps> = ({ vendedores, getProgressoMeta }) => {
  return (
    <div className="space-y-4">
      {vendedores.map((vendedor, index) => {
        const progresso = getProgressoMeta(vendedor.id);
        const posicao = index + 1;
        
        return (
          <div 
            key={vendedor.id}
            className="bg-white rounded-lg shadow-md p-6 flex items-center gap-6 transition-transform hover:scale-[1.02]"
          >
            <div className="flex-shrink-0 w-16 text-center">
              {posicao === 1 && <Trophy className="w-8 h-8 mx-auto text-yellow-400 mb-1" />}
              {posicao === 2 && <Medal className="w-8 h-8 mx-auto text-gray-400 mb-1" />}
              {posicao === 3 && <Medal className="w-8 h-8 mx-auto text-amber-600 mb-1" />}
              <span className="text-2xl font-bold text-gray-700">{posicao}º</span>
            </div>

            <div className="flex-shrink-0">
              {vendedor.foto ? (
                <img 
                  src={vendedor.foto} 
                  alt={vendedor.nome}
                  className="w-20 h-20 rounded-full object-cover border-4 border-gray-200"
                />
              ) : (
                <div className="w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center border-4 border-gray-100">
                  <span className="text-3xl font-bold text-gray-500">
                    {vendedor.nome.charAt(0)}
                  </span>
                </div>
              )}
            </div>

            <div className="flex-grow">
              <h3 className="text-xl font-bold text-gray-800 mb-1">{vendedor.nome}</h3>
              <p className="text-lg text-indigo-600 font-semibold mb-2">
                R$ {vendedor.totalVendas.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
              </p>
              {progresso && (
                <div className="w-full max-w-md">
                  <div className="flex justify-between text-sm text-gray-600 mb-1">
                    <span>Progresso da Meta</span>
                    <span>{progresso.progressoValor.toFixed(1)}%</span>
                  </div>
                  <BarraProgresso 
                    progresso={progresso.progressoValor}
                    corBarra="bg-indigo-600"
                    altura="h-2"
                    animada={true}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};