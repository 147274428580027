import React, { useState } from 'react';
import { Package, Plus } from 'lucide-react';
import { useStore } from '../../../store/useStore';
import { TabelaProdutos } from './TabelaProdutos';
import { FormularioProduto } from './FormularioProduto';
import { Alert } from '../../shared/Alert';

export const GestaoProdutos: React.FC = () => {
  const { produtos, adicionarProduto, editarProduto, removerProduto, ativarDesativarProduto } = useStore();
  const [modalAberto, setModalAberto] = useState(false);
  const [produtoEditando, setProdutoEditando] = useState<Produto | null>(null);
  const [erro, setErro] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    
    const dadosProduto = {
      nome: formData.get('nome') as string,
      descricao: formData.get('descricao') as string,
      preco: Number(formData.get('preco')),
      codigo: formData.get('codigo') as string,
      unidade: formData.get('unidade') as 'unidade' | 'kg' | 'litro' | 'metro',
      ativo: true
    };

    try {
      if (produtoEditando) {
        editarProduto({ ...dadosProduto, id: produtoEditando.id, ativo: produtoEditando.ativo });
      } else {
        adicionarProduto(dadosProduto);
      }
      setModalAberto(false);
      setProdutoEditando(null);
    } catch (error) {
      setErro(error instanceof Error ? error.message : 'Erro ao salvar produto');
    }
  };

  return (
    <div className="p-6">
      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <Package className="w-6 h-6 text-indigo-600" />
          <h2 className="text-2xl font-bold">Gestão de Produtos</h2>
        </div>
        <button
          onClick={() => {
            setProdutoEditando(null);
            setModalAberto(true);
          }}
          className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 flex items-center gap-2"
        >
          <Plus className="w-5 h-5" />
          Novo Produto
        </button>
      </div>

      <TabelaProdutos
        produtos={produtos}
        onEditar={(produto) => {
          setProdutoEditando(produto);
          setModalAberto(true);
        }}
        onRemover={removerProduto}
        onAtivarDesativar={ativarDesativarProduto}
      />

      {modalAberto && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <h3 className="text-xl font-bold mb-4">
              {produtoEditando ? 'Editar Produto' : 'Novo Produto'}
            </h3>
            <FormularioProduto
              produto={produtoEditando}
              onSubmit={handleSubmit}
              onCancel={() => {
                setModalAberto(false);
                setProdutoEditando(null);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};