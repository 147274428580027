import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDRDd9YPbw6nWaMFiOioQEYCbEu8zglWKA",
  authDomain: "ranking-vendas.firebaseapp.com",
  projectId: "ranking-vendas",
  storageBucket: "ranking-vendas.firebasestorage.app",
  messagingSenderId: "1079270540723",
  appId: "1:1079270540723:web:db810f0cbc31f2c73037c6"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export default app;