import React from 'react';
import { 
  AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer 
} from 'recharts';
import { format, startOfMonth, endOfMonth, eachDayOfInterval } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Venda } from '../../../types';

interface GraficoVendasMensaisProps {
  vendas: Venda[];
}

export function GraficoVendasMensais({ vendas }: GraficoVendasMensaisProps) {
  // Calcula o período do mês atual
  const hoje = new Date();
  const inicioMes = startOfMonth(hoje);
  const fimMes = endOfMonth(hoje);
  
  // Gera array com todos os dias do mês
  const diasMes = eachDayOfInterval({ start: inicioMes, end: fimMes });
  
  // Processa os dados para o gráfico
  const dados = diasMes.map(dia => {
    const vendasDoDia = vendas.filter(venda => {
      const dataVenda = new Date(venda.data);
      return (
        dataVenda.getDate() === dia.getDate() &&
        dataVenda.getMonth() === dia.getMonth() &&
        dataVenda.getFullYear() === dia.getFullYear()
      );
    });

    return {
      data: format(dia, 'dd/MM', { locale: ptBR }),
      valor: vendasDoDia.reduce((acc, venda) => acc + venda.valor, 0),
      quantidade: vendasDoDia.reduce((acc, venda) => acc + venda.quantidade, 0)
    };
  });

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
        Vendas do Mês
      </h3>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={dados}>
            <defs>
              <linearGradient id="colorVendas" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#6366f1" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#6366f1" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" className="stroke-gray-200 dark:stroke-gray-700" />
            <XAxis 
              dataKey="data" 
              interval={Math.floor(dados.length / 10)}
              angle={-45}
              textAnchor="end"
              height={60}
              tick={{ fill: '#6B7280' }}
              stroke="#6B7280"
            />
            <YAxis 
              tickFormatter={(value) => `R$ ${value.toLocaleString('pt-BR')}`}
              tick={{ fill: '#6B7280' }}
              stroke="#6B7280"
            />
            <Tooltip 
              formatter={(value: number) => [
                `R$ ${value.toLocaleString('pt-BR')}`,
                'Valor'
              ]}
              labelFormatter={(label) => `Data: ${label}`}
              contentStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                border: 'none',
                borderRadius: '0.5rem',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}
            />
            <Area
              type="monotone"
              dataKey="valor"
              stroke="#6366f1"
              fillOpacity={1}
              fill="url(#colorVendas)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}