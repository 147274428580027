import React from 'react';
import { Trophy, Medal, TrendingUp } from 'lucide-react';
import { BarraProgresso } from '../../shared/BarraProgresso';
import { Usuario } from '../../../types';

interface CompactoLayoutProps {
  vendedores: (Usuario & { totalVendas: number })[];
  getProgressoMeta: (vendedorId: string) => any;
}

export const CompactoLayout: React.FC<CompactoLayoutProps> = ({ vendedores, getProgressoMeta }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Posição
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Vendedor
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Total em Vendas
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Meta
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {vendedores.map((vendedor, index) => {
            const progresso = getProgressoMeta(vendedor.id);
            const posicao = index + 1;

            return (
              <tr key={vendedor.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    {posicao === 1 && <Trophy className="w-5 h-5 text-yellow-400 mr-2" />}
                    {posicao === 2 && <Medal className="w-5 h-5 text-gray-400 mr-2" />}
                    {posicao === 3 && <Medal className="w-5 h-5 text-amber-600 mr-2" />}
                    {posicao > 3 && <TrendingUp className="w-5 h-5 text-gray-400 mr-2" />}
                    <span className="text-sm font-medium text-gray-900">{posicao}º</span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    {vendedor.foto ? (
                      <img 
                        src={vendedor.foto} 
                        alt={vendedor.nome}
                        className="w-10 h-10 rounded-full mr-3"
                      />
                    ) : (
                      <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center mr-3">
                        <span className="text-lg font-medium text-gray-500">
                          {vendedor.nome.charAt(0)}
                        </span>
                      </div>
                    )}
                    <div className="text-sm font-medium text-gray-900">{vendedor.nome}</div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    R$ {vendedor.totalVendas.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {progresso && (
                    <div className="w-32">
                      <BarraProgresso 
                        progresso={progresso.progressoValor}
                        corBarra="bg-indigo-600"
                        altura="h-1.5"
                        animada={true}
                      />
                      <div className="text-xs text-gray-500 mt-1">
                        {progresso.progressoValor.toFixed(1)}%
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};