// Serviço para gerenciar webhooks
import { Webhook, WebhookRequest } from '../types/webhook';
import { logger } from './LogService';

class WebhookService {
  private static instance: WebhookService;
  private readonly STORAGE_KEY = '@RankingVendas:webhooks';
  private readonly API_BASE_URL = 'https://api.linkbot.info';

  private constructor() {}

  static getInstance(): WebhookService {
    if (!WebhookService.instance) {
      WebhookService.instance = new WebhookService();
    }
    return WebhookService.instance;
  }

  // Carrega todos os webhooks do storage
  carregarWebhooks(): Webhook[] {
    try {
      const webhooksJson = localStorage.getItem(this.STORAGE_KEY);
      return webhooksJson ? JSON.parse(webhooksJson) : [];
    } catch (error) {
      logger.error('Erro ao carregar webhooks', error as Error);
      return [];
    }
  }

  // Salva os webhooks no storage
  private salvarWebhooks(webhooks: Webhook[]): void {
    try {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(webhooks));
    } catch (error) {
      logger.error('Erro ao salvar webhooks', error as Error);
      throw error;
    }
  }

  // Cria um novo webhook
  criarWebhook(nome: string): Webhook {
    const webhooks = this.carregarWebhooks();
    
    const novoWebhook: Webhook = {
      id: Date.now().toString(),
      nome,
      url: `${this.API_BASE_URL}/webhook/${Date.now()}`,
      method: 'POST',
      retry: {
        attempts: 3,
        delay: 1000
      },
      timeout: 5000,
      headers: {},
      ativo: false,
      modo: 'test',
      dataCriacao: new Date(),
      stats: {
        test: {
          totalRequests: 0,
          successCount: 0,
          errorCount: 0
        },
        production: {
          totalRequests: 0,
          successCount: 0,
          errorCount: 0
        }
      },
      requests: []
    };

    webhooks.push(novoWebhook);
    this.salvarWebhooks(webhooks);
    logger.info('Webhook criado', { id: novoWebhook.id });
    
    return novoWebhook;
  }

  // Atualiza um webhook existente
  atualizarWebhook(webhook: Webhook): void {
    const webhooks = this.carregarWebhooks();
    const index = webhooks.findIndex(w => w.id === webhook.id);
    
    if (index === -1) {
      throw new Error('Webhook não encontrado');
    }

    webhooks[index] = webhook;
    this.salvarWebhooks(webhooks);
    logger.info('Webhook atualizado', { id: webhook.id });
  }

  // Remove um webhook
  removerWebhook(id: string): void {
    const webhooks = this.carregarWebhooks().filter(w => w.id !== id);
    this.salvarWebhooks(webhooks);
    logger.info('Webhook removido', { id });
  }

  // Testa um webhook
  async testarWebhook(webhookId: string, dados: any): Promise<void> {
    const webhook = this.carregarWebhooks().find(w => w.id === webhookId);
    if (!webhook) throw new Error('Webhook não encontrado');

    try {
      const headers = {
        'Content-Type': 'application/json',
        ...webhook.headers
      };

      const response = await fetch(webhook.url, {
        method: webhook.method,
        headers,
        body: JSON.stringify(dados)
      });

      const request: WebhookRequest = {
        id: Date.now().toString(),
        timestamp: new Date(),
        payload: dados,
        headers,
        sucesso: response.ok,
        erro: response.ok ? undefined : `${response.status}: ${response.statusText}`
      };

      webhook.requests = [request, ...(webhook.requests || [])].slice(0, 50);
      webhook.stats[webhook.modo].totalRequests++;
      
      if (response.ok) {
        webhook.stats[webhook.modo].successCount++;
      } else {
        webhook.stats[webhook.modo].errorCount++;
      }

      webhook.stats[webhook.modo].lastRequest = new Date();
      this.atualizarWebhook(webhook);
      logger.info('Webhook testado com sucesso', { id: webhook.id });

    } catch (error) {
      const request: WebhookRequest = {
        id: Date.now().toString(),
        timestamp: new Date(),
        payload: dados,
        headers: webhook.headers || {},
        sucesso: false,
        erro: error instanceof Error ? error.message : 'Erro desconhecido'
      };

      webhook.requests = [request, ...(webhook.requests || [])].slice(0, 50);
      webhook.stats[webhook.modo].totalRequests++;
      webhook.stats[webhook.modo].errorCount++;
      webhook.stats[webhook.modo].lastRequest = new Date();
      this.atualizarWebhook(webhook);
      logger.error('Erro ao testar webhook', error as Error);
      throw error;
    }
  }

  // Limpa as estatísticas de um webhook
  limparEstatisticas(id: string, modo: 'test' | 'production'): void {
    const webhook = this.carregarWebhooks().find(w => w.id === id);
    if (!webhook) throw new Error('Webhook não encontrado');

    webhook.stats[modo] = {
      totalRequests: 0,
      successCount: 0,
      errorCount: 0
    };

    webhook.requests = [];
    this.atualizarWebhook(webhook);
    logger.info('Estatísticas limpas', { id, modo });
  }
}

export const webhookService = WebhookService.getInstance();