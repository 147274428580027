import { Usuario, Venda } from '../types';

// Calcula o total de vendas para um vendedor específico
export const calcularTotalVendas = (vendas: Venda[], vendedorId: string): number => {
  return vendas
    .filter((v) => v.vendedorId === vendedorId)
    .reduce((acc, v) => acc + v.valor, 0);
};

// Ordena os vendedores por total de vendas em ordem decrescente
export const ordenarVendedoresPorVendas = (
  usuarios: Usuario[],
  vendas: Venda[]
): (Usuario & { totalVendas: number })[] => {
  return usuarios
    // Filtra apenas usuários do tipo vendedor
    .filter((u) => u.tipo === 'vendedor')
    // Adiciona o total de vendas para cada vendedor
    .map((vendedor) => ({
      ...vendedor,
      totalVendas: calcularTotalVendas(vendas, vendedor.id),
    }))
    // Ordena por valor total de vendas (maior para menor)
    .sort((a, b) => b.totalVendas - a.totalVendas);
};