import React from 'react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer 
} from 'recharts';
import { Usuario, Venda } from '../../../types';

interface GraficoVendasPorVendedorProps {
  vendas: Venda[];
  usuarios: Usuario[];
}

export function GraficoVendasPorVendedor({ vendas, usuarios }: GraficoVendasPorVendedorProps) {
  const vendedores = usuarios.filter(u => u.tipo === 'vendedor');
  
  const dados = vendedores.map(vendedor => {
    const vendasVendedor = vendas.filter(v => v.vendedorId === vendedor.id);
    const totalVendas = vendasVendedor.reduce((acc, v) => acc + v.valor, 0);
    
    return {
      nome: vendedor.nome,
      valor: totalVendas
    };
  }).sort((a, b) => b.valor - a.valor);

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
        Vendas por Vendedor
      </h3>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={dados}>
            <CartesianGrid strokeDasharray="3 3" className="stroke-gray-200 dark:stroke-gray-700" />
            <XAxis 
              dataKey="nome" 
              angle={-45}
              textAnchor="end"
              height={60}
              interval={0}
              tick={{ fill: '#6B7280' }}
              stroke="#6B7280"
            />
            <YAxis 
              tickFormatter={(value) => `R$ ${value.toLocaleString('pt-BR')}`}
              tick={{ fill: '#6B7280' }}
              stroke="#6B7280"
            />
            <Tooltip
              formatter={(value: number) => [
                `R$ ${value.toLocaleString('pt-BR')}`,
                'Total em Vendas'
              ]}
              contentStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                border: 'none',
                borderRadius: '0.5rem',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}
            />
            <Bar 
              dataKey="valor" 
              fill="#6366f1"
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}