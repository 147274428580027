import React from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Venda, Meta } from '../../types';
import { calcularProgressoMeta } from '../../utils/metas';
import { formatarMoeda } from '../../utils/formatacao';

interface RelatorioDesempenhoProps {
  vendas: Venda[];
  metas: Meta[];
}

export const RelatorioDesempenho: React.FC<RelatorioDesempenhoProps> = ({
  vendas,
  metas
}) => {
  // Calcula estatísticas gerais
  const totalVendas = vendas.reduce((acc, venda) => acc + venda.valor, 0);
  const mediaVendas = totalVendas / (vendas.length || 1);
  const maiorVenda = Math.max(...vendas.map(v => v.valor), 0);
  const menorVenda = Math.min(...(vendas.length ? vendas.map(v => v.valor) : [0]));
  
  // Calcula metas atingidas
  const metasAtingidas = metas.filter(meta => {
    const progresso = calcularProgressoMeta(meta, vendas);
    return progresso.metaBatida;
  });

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h3 className="text-lg font-semibold mb-6">Relatório de Desempenho</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div>
            <h4 className="text-sm font-medium text-gray-500 mb-1">
              Total em Vendas
            </h4>
            <p className="text-2xl font-bold text-indigo-600">
              {formatarMoeda(totalVendas)}
            </p>
          </div>

          <div>
            <h4 className="text-sm font-medium text-gray-500 mb-1">
              Média por Venda
            </h4>
            <p className="text-2xl font-bold text-green-600">
              {formatarMoeda(mediaVendas)}
            </p>
          </div>
        </div>

        <div className="space-y-4">
          <div>
            <h4 className="text-sm font-medium text-gray-500 mb-1">
              Maior Venda
            </h4>
            <p className="text-2xl font-bold text-blue-600">
              {formatarMoeda(maiorVenda)}
            </p>
          </div>

          <div>
            <h4 className="text-sm font-medium text-gray-500 mb-1">
              Metas Atingidas
            </h4>
            <p className="text-2xl font-bold text-yellow-600">
              {metasAtingidas.length} de {metas.length}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h4 className="text-sm font-medium text-gray-500 mb-4">
          Últimas Vendas
        </h4>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                  Data
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                  Valor
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                  Quantidade
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {vendas.slice(0, 5).map((venda) => (
                <tr key={venda.id}>
                  <td className="px-4 py-2 text-sm text-gray-900">
                    {format(new Date(venda.data), "dd/MM/yyyy HH:mm", { locale: ptBR })}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-900">
                    {formatarMoeda(venda.valor)}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-900">
                    {venda.quantidade} un.
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};