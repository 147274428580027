import React, { useState } from 'react';
import { Camera, X } from 'lucide-react';
import { Usuario } from '../../../types';
import { Alert } from '../../shared/Alert';

interface FormularioUsuarioProps {
  formData: {
    nome: string;
    email: string;
    telefone: string;
    tipo: string;
    senha: string;
    foto?: string;
  };
  setFormData: (data: any) => void;
  onSubmit: (e: React.FormEvent) => void;
  onCancel: () => void;
  modoEdicao: boolean;
  usuarioEditando?: Usuario;
}

export const FormularioUsuario: React.FC<FormularioUsuarioProps> = ({
  formData,
  setFormData,
  onSubmit,
  onCancel,
  modoEdicao,
  usuarioEditando
}) => {
  const [erro, setErro] = useState<string | null>(null);
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErro(null);

    if (!modoEdicao && formData.senha.length < 6) {
      setErro('A senha deve ter pelo menos 6 caracteres');
      return;
    }

    if (!modoEdicao && formData.senha !== confirmarSenha) {
      setErro('As senhas não conferem');
      return;
    }

    onSubmit(e);
  };

  const handleFotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const arquivo = event.target.files?.[0];
    if (!arquivo) return;

    // Validar tipo de arquivo
    if (!arquivo.type.startsWith('image/')) {
      setErro('Por favor, selecione uma imagem válida');
      return;
    }

    // Validar tamanho (máximo 2MB)
    if (arquivo.size > 2 * 1024 * 1024) {
      setErro('A imagem deve ter no máximo 2MB');
      return;
    }

    try {
      // Converter para Base64
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setFormData({ ...formData, foto: reader.result });
        }
      };
      reader.readAsDataURL(arquivo);
    } catch (error) {
      setErro('Erro ao processar a imagem');
    }
  };

  const removerFoto = () => {
    setFormData({ ...formData, foto: undefined });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      <div className="flex items-center gap-6 mb-6">
        <div className="relative">
          {formData.foto ? (
            <div className="relative">
              <img
                src={formData.foto}
                alt={formData.nome}
                className="w-24 h-24 rounded-full object-cover"
              />
              <button
                type="button"
                onClick={removerFoto}
                className="absolute -top-2 -right-2 p-1 bg-red-100 text-red-600 rounded-full hover:bg-red-200"
                title="Remover foto"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-100 flex items-center justify-center">
              <span className="text-3xl font-semibold text-gray-400">
                {formData.nome ? formData.nome.charAt(0) : '?'}
              </span>
            </div>
          )}
        </div>

        <div className="space-y-2">
          <button
            type="button"
            onClick={() => inputFileRef.current?.click()}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 bg-indigo-50 rounded-md hover:bg-indigo-100"
          >
            <Camera className="w-4 h-4" />
            {formData.foto ? 'Alterar foto' : 'Adicionar foto'}
          </button>
          <p className="text-sm text-gray-500">
            JPG ou PNG, máximo 2MB
          </p>
        </div>

        <input
          ref={inputFileRef}
          type="file"
          accept="image/jpeg,image/png"
          onChange={handleFotoChange}
          className="hidden"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Nome
        </label>
        <input
          type="text"
          value={formData.nome}
          onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
          className="w-full px-3 py-2 border rounded-md"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Email
        </label>
        <input
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="w-full px-3 py-2 border rounded-md"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Telefone (opcional)
        </label>
        <input
          type="tel"
          value={formData.telefone}
          onChange={(e) => setFormData({ ...formData, telefone: e.target.value })}
          className="w-full px-3 py-2 border rounded-md"
          placeholder="(00) 00000-0000"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Tipo
        </label>
        <select
          value={formData.tipo}
          onChange={(e) => setFormData({ ...formData, tipo: e.target.value })}
          className="w-full px-3 py-2 border rounded-md"
          required
        >
          <option value="vendedor">Vendedor</option>
          <option value="supervisor">Supervisor</option>
          <option value="admin">Administrador</option>
        </select>
      </div>

      {!modoEdicao && (
        <>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Senha
            </label>
            <input
              type="password"
              value={formData.senha}
              onChange={(e) => setFormData({ ...formData, senha: e.target.value })}
              className="w-full px-3 py-2 border rounded-md"
              required
              minLength={6}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Confirmar Senha
            </label>
            <input
              type="password"
              value={confirmarSenha}
              onChange={(e) => setConfirmarSenha(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
              required
              minLength={6}
            />
          </div>
        </>
      )}

      <div className="flex justify-end gap-4 mt-6">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md"
        >
          {modoEdicao ? 'Salvar Alterações' : 'Criar Usuário'}
        </button>
      </div>
    </form>
  );
};