import React, { useState } from 'react';
import { Play, ToggleLeft, ToggleRight, Trash2 } from 'lucide-react';
import { Webhook } from '../../../types/webhook';
import { WebhookStats } from './WebhookStats';
import { WebhookRequestLog } from './WebhookRequestLog';

interface WebhookModoTesteProps {
  webhook: Webhook;
  onToggleAtivo: (ativo: boolean) => void;
  onTestar: (dados: any) => Promise<void>;
  onLimparEstatisticas: () => void;
}

export const WebhookModoTeste: React.FC<WebhookModoTesteProps> = ({
  webhook,
  onToggleAtivo,
  onTestar,
  onLimparEstatisticas
}) => {
  const [testando, setTestando] = useState(false);
  const { ativo, modo } = webhook;

  const handleTestar = async () => {
    if (!ativo) return;

    setTestando(true);
    try {
      const dadosTeste = {
        vendedorId: "teste_123",
        valor: 1000.00,
        quantidade: 1,
        data: new Date().toISOString(),
        origem: "teste"
      };

      await onTestar(dadosTeste);
    } finally {
      setTestando(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-semibold">{webhook.nome}</h3>
          <div className="flex items-center gap-4">
            <button
              onClick={() => onToggleAtivo(!ativo)}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
            >
              {ativo ? (
                <>
                  <ToggleRight className="w-6 h-6 text-green-600" />
                  <span className="text-green-600">Ativo</span>
                </>
              ) : (
                <>
                  <ToggleLeft className="w-6 h-6 text-gray-400" />
                  <span>Inativo</span>
                </>
              )}
            </button>

            <button
              onClick={onLimparEstatisticas}
              className="flex items-center gap-2 text-red-600 hover:text-red-800"
              title="Limpar estatísticas"
            >
              <Trash2 className="w-5 h-5" />
              <span>Limpar</span>
            </button>
          </div>
        </div>

        <div className="space-y-4">
          <div>
            <p className="text-sm text-gray-600 mb-1">URL do Webhook</p>
            <code className="block bg-gray-50 p-3 rounded-md text-sm">
              {webhook.url}
            </code>
          </div>

          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-600">Modo: {modo === 'test' ? 'Teste' : 'Produção'}</p>
              <p className="text-xs text-gray-500 mt-1">
                {modo === 'test' 
                  ? 'As requisições não afetarão os dados reais'
                  : 'As requisições afetarão os dados reais'}
              </p>
            </div>

            <button
              onClick={handleTestar}
              disabled={!ativo || testando}
              className={`
                flex items-center gap-2 px-4 py-2 rounded-md
                ${ativo 
                  ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                }
              `}
            >
              {testando ? (
                <>
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  <span>Testando...</span>
                </>
              ) : (
                <>
                  <Play className="w-4 h-4" />
                  <span>Testar Webhook</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      <WebhookStats stats={webhook.stats[modo]} modo={modo} />

      <WebhookRequestLog requests={webhook.requests} />
    </div>
  );
};