import { Meta, Venda, CampoPersonalizado } from '../types';

export const validarMeta = (meta: Partial<Meta>) => {
  const erros: string[] = [];

  if (!meta.vendedorId) {
    erros.push('Vendedor é obrigatório');
  }

  if (!meta.tipo) {
    erros.push('Tipo de meta é obrigatório');
  }

  if (meta.tipo === 'valor' && (!meta.valorMeta || meta.valorMeta <= 0)) {
    erros.push('Valor da meta deve ser maior que zero');
  }

  if (meta.tipo === 'quantidade' && (!meta.quantidadeMeta || meta.quantidadeMeta <= 0)) {
    erros.push('Quantidade da meta deve ser maior que zero');
  }

  if (!meta.dataInicio || !meta.dataFim) {
    erros.push('Período da meta é obrigatório');
  } else if (new Date(meta.dataInicio) >= new Date(meta.dataFim)) {
    erros.push('Data de início deve ser anterior à data de fim');
  }

  return {
    valido: erros.length === 0,
    erros
  };
};

export const validarVenda = (venda: Partial<Venda>) => {
  const erros: string[] = [];

  if (!venda.vendedorId) {
    erros.push('Vendedor é obrigatório');
  }

  if (venda.valor !== undefined && venda.valor <= 0) {
    erros.push('Valor da venda deve ser maior que zero');
  }

  if (venda.quantidade !== undefined && venda.quantidade <= 0) {
    erros.push('Quantidade deve ser maior que zero');
  }

  if (!venda.data) {
    erros.push('Data da venda é obrigatória');
  }

  return {
    valido: erros.length === 0,
    erros
  };
};

export const validarCampoPersonalizado = (campo: Partial<CampoPersonalizado>) => {
  const erros: string[] = [];

  if (!campo.nome?.trim()) {
    erros.push('Nome é obrigatório');
  }

  if (!campo.chave?.trim()) {
    erros.push('Chave é obrigatória');
  } else if (!/^[a-zA-Z0-9_]+$/.test(campo.chave)) {
    erros.push('Chave deve conter apenas letras, números e underscore');
  }

  if (!campo.tipo) {
    erros.push('Tipo é obrigatório');
  }

  return {
    valido: erros.length === 0,
    erros
  };
};