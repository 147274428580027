import React, { useState, useEffect } from 'react';
import { DollarSign, Package, Plus } from 'lucide-react';
import { useStore } from '../../store/useStore';
import { Alert } from '../shared/Alert';
import { useNotificacoes } from '../../contexts/NotificacoesContext';
import { Venda } from '../../types';

interface RegistrarVendaProps {
  vendaEditando?: Venda | null;
  onClose?: () => void;
}

export const RegistrarVenda: React.FC<RegistrarVendaProps> = ({
  vendaEditando,
  onClose
}) => {
  const { produtos, usuarioAtual, adicionarVenda, editarVenda } = useStore();
  const { adicionarNotificacaoVenda } = useNotificacoes();
  const [erro, setErro] = useState<string | null>(null);
  const [sucesso, setSucesso] = useState<string | null>(null);
  const [modalAberto, setModalAberto] = useState(false);

  const [formData, setFormData] = useState({
    produtoId: '',
    quantidade: 1,
    valor: 0,
    data: new Date().toISOString().slice(0, 16) // Format: YYYY-MM-DDTHH:mm
  });

  // Initialize form with editing data
  useEffect(() => {
    if (vendaEditando) {
      setFormData({
        produtoId: vendaEditando.produtoId || '',
        quantidade: vendaEditando.quantidade,
        valor: vendaEditando.valor,
        data: new Date(vendaEditando.data).toISOString().slice(0, 16)
      });
      setModalAberto(true);
    }
  }, [vendaEditando]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErro(null);

    if (!usuarioAtual) {
      setErro('Usuário não autenticado');
      return;
    }

    try {
      const vendaData = {
        vendedorId: usuarioAtual.id,
        produtoId: formData.produtoId || undefined,
        valor: formData.valor,
        quantidade: formData.quantidade,
        data: new Date(formData.data),
        origem: 'manual' as const
      };

      if (vendaEditando) {
        editarVenda({ ...vendaData, id: vendaEditando.id });
        setSucesso('Venda atualizada com sucesso!');
      } else {
        adicionarVenda(vendaData);
        adicionarNotificacaoVenda(usuarioAtual, formData.valor);
        setSucesso('Venda registrada com sucesso!');
      }

      setModalAberto(false);
      if (onClose) onClose();
      
      // Reset form
      setFormData({
        produtoId: '',
        quantidade: 1,
        valor: 0,
        data: new Date().toISOString().slice(0, 16)
      });
    } catch (error) {
      setErro(error instanceof Error ? error.message : 'Erro ao registrar venda');
    }
  };

  const handleProdutoChange = (produtoId: string) => {
    const produto = produtos.find(p => p.id === produtoId);
    setFormData(prev => ({
      ...prev,
      produtoId,
      valor: produto ? produto.preco * prev.quantidade : 0
    }));
  };

  const handleQuantidadeChange = (quantidade: number) => {
    const produto = produtos.find(p => p.id === formData.produtoId);
    setFormData(prev => ({
      ...prev,
      quantidade,
      valor: produto ? produto.preco * quantidade : prev.valor
    }));
  };

  return (
    <>
      {!vendaEditando && (
        <button
          onClick={() => setModalAberto(true)}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          <Plus className="w-5 h-5" />
          Registrar Venda
        </button>
      )}

      {modalAberto && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">
              {vendaEditando ? 'Editar Venda' : 'Registrar Nova Venda'}
            </h3>

            {erro && (
              <Alert 
                tipo="erro" 
                mensagem={erro} 
                onClose={() => setErro(null)} 
              />
            )}

            {sucesso && (
              <Alert 
                tipo="sucesso" 
                mensagem={sucesso} 
                onClose={() => setSucesso(null)} 
              />
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Produto (opcional)
                </label>
                <select
                  value={formData.produtoId}
                  onChange={(e) => handleProdutoChange(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md"
                >
                  <option value="">Selecione um produto</option>
                  {produtos.filter(p => p.ativo).map((produto) => (
                    <option key={produto.id} value={produto.id}>
                      {produto.nome} - {produto.preco.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Quantidade
                </label>
                <div className="flex items-center gap-2">
                  <Package className="w-5 h-5 text-gray-400" />
                  <input
                    type="number"
                    value={formData.quantidade}
                    onChange={(e) => handleQuantidadeChange(Number(e.target.value))}
                    min="1"
                    className="w-full px-3 py-2 border rounded-md"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Valor Total
                </label>
                <div className="flex items-center gap-2">
                  <DollarSign className="w-5 h-5 text-gray-400" />
                  <input
                    type="number"
                    value={formData.valor}
                    onChange={(e) => setFormData({ ...formData, valor: Number(e.target.value) })}
                    min="0.01"
                    step="0.01"
                    className="w-full px-3 py-2 border rounded-md"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Data e Hora
                </label>
                <input
                  type="datetime-local"
                  value={formData.data}
                  onChange={(e) => setFormData({ ...formData, data: e.target.value })}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>

              <div className="flex justify-end gap-4 mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setModalAberto(false);
                    if (onClose) onClose();
                  }}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md"
                >
                  {vendaEditando ? 'Atualizar Venda' : 'Registrar Venda'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};