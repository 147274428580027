import React from 'react';
import { Target, Calendar, DollarSign, Package, Trash2, Edit } from 'lucide-react';
import { Meta, Usuario } from '../../../types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { calcularProgressoMeta } from '../../../utils/metas';
import { BarraProgresso } from '../../shared/BarraProgresso';
import { useStore } from '../../../store/useStore';

interface CardMetaProps {
  meta: Meta;
  vendedor: Usuario;
  onRemover: (id: string) => void;
  onEditar: (meta: Meta) => void;
}

export const CardMeta: React.FC<CardMetaProps> = ({ 
  meta, 
  vendedor, 
  onRemover,
  onEditar
}) => {
  const vendas = useStore(state => state.vendas);
  const progresso = calcularProgressoMeta(meta, vendas);

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center gap-2">
          <Target className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{vendedor.nome}</h3>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => onEditar(meta)}
            className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300"
            title="Editar meta"
          >
            <Edit className="w-5 h-5" />
          </button>
          <button
            onClick={() => onRemover(meta.id)}
            className="text-red-600 dark:text-red-400 hover:text-red-800 dark:hover:text-red-300"
            title="Remover meta"
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="flex items-center gap-2 text-gray-600 dark:text-gray-300 mb-4">
        <Calendar className="w-4 h-4" />
        <span className="text-sm">
          {format(new Date(meta.dataInicio), "dd 'de' MMMM", { locale: ptBR })} - 
          {format(new Date(meta.dataFim), "dd 'de' MMMM", { locale: ptBR })}
        </span>
      </div>

      <div className="space-y-4">
        {meta.tipo === 'valor' && meta.valorMeta && (
          <div>
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <DollarSign className="w-4 h-4 text-green-600 dark:text-green-400" />
                <p className="text-sm text-gray-600 dark:text-gray-300">Meta de Valor</p>
              </div>
              <p className="text-sm font-medium text-gray-900 dark:text-white">
                R$ {progresso.valorAtingido.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} 
                <span className="text-gray-500 dark:text-gray-400">
                  / R$ {meta.valorMeta.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                </span>
              </p>
            </div>
            <BarraProgresso 
              progresso={progresso.progressoValor} 
              corBarra="bg-green-600 dark:bg-green-500" 
              animada={true}
            />
          </div>
        )}

        {meta.tipo === 'quantidade' && meta.quantidadeMeta && (
          <div>
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <Package className="w-4 h-4 text-blue-600 dark:text-blue-400" />
                <p className="text-sm text-gray-600 dark:text-gray-300">Meta de Quantidade</p>
              </div>
              <p className="text-sm font-medium text-gray-900 dark:text-white">
                {progresso.quantidadeAtingida} 
                <span className="text-gray-500 dark:text-gray-400">
                  / {meta.quantidadeMeta} unidades
                </span>
              </p>
            </div>
            <BarraProgresso 
              progresso={progresso.progressoQuantidade} 
              corBarra="bg-blue-600 dark:bg-blue-500" 
              animada={true}
            />
          </div>
        )}

        {meta.bonificacao && (
          <div className="mt-4 pt-4 border-t dark:border-gray-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <DollarSign className="w-4 h-4 text-yellow-600 dark:text-yellow-400" />
                <p className="text-sm text-gray-600 dark:text-gray-300">Bonificação</p>
              </div>
              <p className={`font-semibold ${
                progresso.metaBatida 
                  ? 'text-green-600 dark:text-green-400' 
                  : 'text-yellow-600 dark:text-yellow-400'
              }`}>
                R$ {meta.bonificacao.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                {progresso.metaBatida && ' (Conquistada!)'}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};