import React, { useState } from 'react';
import { webhookService } from '../../../services/WebhookService';
import { ListaWebhooks } from './ListaWebhooks';
import { NovoWebhookModal } from './NovoWebhookModal';
import { WebhookModoTeste } from './WebhookModoTeste';
import { Alert } from '../../shared/Alert';
import { Webhook } from '../../../types/webhook';

export const WebhookPage: React.FC = () => {
  const [modalAberto, setModalAberto] = useState(false);
  const [webhookSelecionado, setWebhookSelecionado] = useState<Webhook | null>(null);
  const [erro, setErro] = useState<string | null>(null);
  const [sucesso, setSucesso] = useState<string | null>(null);
  
  const webhooks = webhookService.carregarWebhooks();

  const handleNovoWebhook = (nome: string) => {
    try {
      webhookService.criarWebhook(nome);
      setModalAberto(false);
      setSucesso('Webhook criado com sucesso!');
    } catch (error) {
      setErro('Erro ao criar webhook');
    }
  };

  const handleRemoverWebhook = (id: string) => {
    try {
      webhookService.removerWebhook(id);
      setSucesso('Webhook removido com sucesso!');
      if (webhookSelecionado?.id === id) {
        setWebhookSelecionado(null);
      }
    } catch (error) {
      setErro('Erro ao remover webhook');
    }
  };

  const handleTestarWebhook = async (dados: any) => {
    if (!webhookSelecionado) return;
    
    try {
      await webhookService.testarWebhook(webhookSelecionado.id, dados);
      setSucesso('Teste realizado com sucesso!');
    } catch (error) {
      setErro('Erro ao testar webhook');
      throw error;
    }
  };

  const handleToggleAtivo = (ativo: boolean) => {
    if (!webhookSelecionado) return;

    try {
      const webhookAtualizado = {
        ...webhookSelecionado,
        ativo
      };
      webhookService.atualizarWebhook(webhookAtualizado);
      setWebhookSelecionado(webhookAtualizado);
      setSucesso(`Webhook ${ativo ? 'ativado' : 'desativado'} com sucesso!`);
    } catch (error) {
      setErro('Erro ao atualizar webhook');
    }
  };

  const handleLimparEstatisticas = () => {
    if (!webhookSelecionado) return;

    try {
      webhookService.limparEstatisticas(webhookSelecionado.id, webhookSelecionado.modo);
      const webhookAtualizado = webhookService.carregarWebhooks()
        .find(w => w.id === webhookSelecionado.id);
      
      if (webhookAtualizado) {
        setWebhookSelecionado(webhookAtualizado);
      }
      setSucesso('Estatísticas limpas com sucesso!');
    } catch (error) {
      setErro('Erro ao limpar estatísticas');
    }
  };

  return (
    <div className="p-6 space-y-6">
      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      {sucesso && (
        <Alert 
          tipo="sucesso" 
          mensagem={sucesso} 
          onClose={() => setSucesso(null)} 
        />
      )}

      <ListaWebhooks
        webhooks={webhooks}
        onAdicionar={() => setModalAberto(true)}
        onEditar={setWebhookSelecionado}
        onRemover={handleRemoverWebhook}
      />

      {webhookSelecionado && (
        <WebhookModoTeste
          webhook={webhookSelecionado}
          onToggleAtivo={handleToggleAtivo}
          onTestar={handleTestarWebhook}
          onLimparEstatisticas={handleLimparEstatisticas}
        />
      )}

      {modalAberto && (
        <NovoWebhookModal
          onClose={() => setModalAberto(false)}
          onConfirm={handleNovoWebhook}
        />
      )}
    </div>
  );
};