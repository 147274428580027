// Seletor de layouts para o painel de ranking
import React from 'react';
import { Layout, Grid, List, Columns, Trophy } from 'lucide-react';

// Tipos de layout disponíveis
export type LayoutType = 'padrao' | 'compacto' | 'lista' | 'grade' | 'gamificado';

interface LayoutSelectorProps {
  layoutAtual: LayoutType;
  onLayoutChange: (layout: LayoutType) => void;
}

export const LayoutSelector: React.FC<LayoutSelectorProps> = ({
  layoutAtual,
  onLayoutChange
}) => {
  // Configuração dos layouts disponíveis
  const layouts = [
    { id: 'padrao', nome: 'Padrão', icone: Layout },
    { id: 'compacto', nome: 'Compacto', icone: Columns },
    { id: 'lista', nome: 'Lista', icone: List },
    { id: 'grade', nome: 'Grade', icone: Grid },
    { id: 'gamificado', nome: 'Gamificado', icone: Trophy }
  ] as const;

  return (
    <div className="flex gap-2">
      {layouts.map(({ id, nome, icone: Icon }) => (
        <button
          key={id}
          onClick={() => onLayoutChange(id as LayoutType)}
          className={`p-2 rounded-lg flex items-center gap-2 transition-colors ${
            layoutAtual === id
              ? 'bg-indigo-100 text-indigo-600'
              : 'hover:bg-gray-100 text-gray-600'
          }`}
          title={`Layout ${nome}`}
        >
          <Icon className="w-5 h-5" />
          <span className="text-sm font-medium">{nome}</span>
        </button>
      ))}
    </div>
  );
};