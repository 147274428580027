import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Tipos de log
export enum LogLevel {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  DEBUG = 'debug'
}

// Interface para um log
interface LogEntry {
  level: LogLevel;
  message: string;
  timestamp: Date;
  context?: any;
  error?: Error;
}

// Configuração do serviço de logs
class LogService {
  private static instance: LogService;
  private logs: LogEntry[] = [];
  private readonly maxLogs = 1000;

  private constructor() {}

  static getInstance(): LogService {
    if (!LogService.instance) {
      LogService.instance = new LogService();
    }
    return LogService.instance;
  }

  private addLog(entry: LogEntry) {
    this.logs.unshift(entry);
    
    if (this.logs.length > this.maxLogs) {
      this.logs = this.logs.slice(0, this.maxLogs);
    }

    const formattedDate = format(entry.timestamp, 'dd/MM/yyyy HH:mm:ss', { locale: ptBR });
    const contextStr = entry.context ? JSON.stringify(entry.context, null, 2) : '';
    const errorStr = entry.error ? entry.error.stack : '';

    switch (entry.level) {
      case LogLevel.INFO:
        console.info(`[${formattedDate}] INFO: ${entry.message}`);
        if (contextStr) console.info('Contexto:', contextStr);
        break;
      case LogLevel.WARN:
        console.warn(`[${formattedDate}] WARN: ${entry.message}`);
        if (contextStr) console.warn('Contexto:', contextStr);
        break;
      case LogLevel.ERROR:
        console.error(`[${formattedDate}] ERROR: ${entry.message}`);
        if (contextStr) console.error('Contexto:', contextStr);
        if (errorStr) console.error('Erro:', errorStr);
        break;
      case LogLevel.DEBUG:
        console.debug(`[${formattedDate}] DEBUG: ${entry.message}`);
        if (contextStr) console.debug('Contexto:', contextStr);
        break;
    }
  }

  info(message: string, context?: any) {
    this.addLog({
      level: LogLevel.INFO,
      message,
      timestamp: new Date(),
      context
    });
  }

  warn(message: string, context?: any) {
    this.addLog({
      level: LogLevel.WARN,
      message,
      timestamp: new Date(),
      context
    });
  }

  error(message: string, error?: Error, context?: any) {
    this.addLog({
      level: LogLevel.ERROR,
      message,
      timestamp: new Date(),
      error,
      context
    });
  }

  debug(message: string, context?: any) {
    if (process.env.NODE_ENV === 'development') {
      this.addLog({
        level: LogLevel.DEBUG,
        message,
        timestamp: new Date(),
        context
      });
    }
  }

  getLogs(): LogEntry[] {
    return [...this.logs];
  }

  clearLogs() {
    this.logs = [];
  }

  exportLogs(): string {
    return this.logs
      .map(log => {
        const formattedDate = format(log.timestamp, 'dd/MM/yyyy HH:mm:ss', { locale: ptBR });
        const base = `[${formattedDate}] ${log.level.toUpperCase()}: ${log.message}`;
        const context = log.context ? `\nContexto: ${JSON.stringify(log.context, null, 2)}` : '';
        const error = log.error ? `\nErro: ${log.error.stack}` : '';
        return `${base}${context}${error}\n`;
      })
      .join('\n');
  }
}

export const logger = LogService.getInstance();