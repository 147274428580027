import React, { useState } from 'react';
import { Target, Plus } from 'lucide-react';
import { useStore } from '../../../store/useStore';
import { FormularioMeta } from './FormularioMeta';
import { CardMeta } from './CardMeta';
import { Meta } from '../../../types';
import { Alert } from '../../shared/Alert';

export const GestaoMetas: React.FC = () => {
  const { metas, usuarios, vendas, adicionarMeta, removerMeta, editarMeta } = useStore();
  const [modalAberto, setModalAberto] = useState(false);
  const [metaEditando, setMetaEditando] = useState<Meta | null>(null);
  const [erro, setErro] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    
    const dadosMeta = {
      vendedorId: formData.get('vendedorId') as string,
      tipo: formData.get('tipo') as 'valor' | 'quantidade',
      valorMeta: formData.get('valorMeta') ? Number(formData.get('valorMeta')) : undefined,
      quantidadeMeta: formData.get('quantidadeMeta') ? Number(formData.get('quantidadeMeta')) : undefined,
      dataInicio: formData.get('dataInicio') as string,
      dataFim: formData.get('dataFim') as string,
      bonificacao: Number(formData.get('bonificacao'))
    };

    try {
      if (metaEditando) {
        editarMeta({ ...dadosMeta, id: metaEditando.id });
      } else {
        adicionarMeta(dadosMeta);
      }
      setModalAberto(false);
      setMetaEditando(null);
    } catch (error) {
      setErro(error instanceof Error ? error.message : 'Erro ao salvar meta');
    }
  };

  // Agrupa metas por vendedor
  const metasPorVendedor = metas.reduce((acc, meta) => {
    const vendedor = usuarios.find(u => u.id === meta.vendedorId);
    if (!vendedor) return acc;

    if (!acc[meta.vendedorId]) {
      acc[meta.vendedorId] = [];
    }
    acc[meta.vendedorId].push(meta);
    return acc;
  }, {} as Record<string, Meta[]>);

  // Divide os vendedores em duas colunas
  const vendedoresIds = Object.keys(metasPorVendedor);
  const metadeVendedores = Math.ceil(vendedoresIds.length / 2);
  const coluna1 = vendedoresIds.slice(0, metadeVendedores);
  const coluna2 = vendedoresIds.slice(metadeVendedores);

  return (
    <div className="p-6">
      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-2">
          <Target className="w-6 h-6 text-indigo-600 dark:text-indigo-400" />
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Gestão de Metas</h2>
        </div>
        <button
          onClick={() => {
            setMetaEditando(null);
            setModalAberto(true);
          }}
          className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 flex items-center gap-2"
        >
          <Plus className="w-5 h-5" />
          Nova Meta
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Coluna 1 */}
        <div className="space-y-6">
          {coluna1.map(vendedorId => {
            const vendedor = usuarios.find(u => u.id === vendedorId);
            if (!vendedor) return null;

            return (
              <div key={vendedorId} className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                  Metas de {vendedor.nome}
                </h3>
                <div className="space-y-4">
                  {metasPorVendedor[vendedorId].map((meta) => (
                    <CardMeta
                      key={meta.id}
                      meta={meta}
                      vendedor={vendedor}
                      onRemover={removerMeta}
                      onEditar={(meta) => {
                        setMetaEditando(meta);
                        setModalAberto(true);
                      }}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>

        {/* Coluna 2 */}
        <div className="space-y-6">
          {coluna2.map(vendedorId => {
            const vendedor = usuarios.find(u => u.id === vendedorId);
            if (!vendedor) return null;

            return (
              <div key={vendedorId} className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                  Metas de {vendedor.nome}
                </h3>
                <div className="space-y-4">
                  {metasPorVendedor[vendedorId].map((meta) => (
                    <CardMeta
                      key={meta.id}
                      meta={meta}
                      vendedor={vendedor}
                      onRemover={removerMeta}
                      onEditar={(meta) => {
                        setMetaEditando(meta);
                        setModalAberto(true);
                      }}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {modalAberto && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
            <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">
              {metaEditando ? 'Editar Meta' : 'Nova Meta'}
            </h3>
            <FormularioMeta
              onSubmit={handleSubmit}
              onCancel={() => {
                setModalAberto(false);
                setMetaEditando(null);
              }}
              metaEditando={metaEditando}
            />
          </div>
        </div>
      )}
    </div>
  );
};