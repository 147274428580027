import { useState } from 'react';
import { useStore } from '../store/useStore';
import { Usuario } from '../types';

export const usePerfilUsuario = () => {
  const { usuarioAtual, editarUsuario } = useStore();
  const [erro, setErro] = useState<string | null>(null);
  const [sucesso, setSucesso] = useState<string | null>(null);

  const handleFotoChange = async (arquivo: File) => {
    try {
      setErro(null);
      setSucesso(null);

      if (!arquivo.type.startsWith('image/')) {
        setErro('Por favor, selecione uma imagem válida');
        return;
      }

      if (arquivo.size > 2 * 1024 * 1024) {
        setErro('A imagem deve ter no máximo 2MB');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string' && usuarioAtual) {
          editarUsuario({
            ...usuarioAtual,
            foto: reader.result
          });
          setSucesso('Foto atualizada com sucesso!');
        }
      };
      reader.readAsDataURL(arquivo);
    } catch (error) {
      setErro('Erro ao processar a imagem');
    }
  };

  const handleRemoverFoto = () => {
    if (usuarioAtual) {
      editarUsuario({
        ...usuarioAtual,
        foto: undefined
      });
      setSucesso('Foto removida com sucesso!');
    }
  };

  const handleAlterarSenha = (senhaAtual: string, novaSenha: string) => {
    setErro(null);
    setSucesso(null);

    if (!usuarioAtual) return;

    if (senhaAtual !== usuarioAtual.senha) {
      setErro('Senha atual incorreta');
      return;
    }

    if (novaSenha.length < 6) {
      setErro('A nova senha deve ter pelo menos 6 caracteres');
      return;
    }

    editarUsuario({
      ...usuarioAtual,
      senha: novaSenha
    });

    setSucesso('Senha alterada com sucesso!');
  };

  return {
    usuario: usuarioAtual,
    erro,
    sucesso,
    setErro,
    setSucesso,
    handleFotoChange,
    handleRemoverFoto,
    handleAlterarSenha
  };
};