import { create } from 'zustand';
import { Usuario, Venda, Meta, Produto } from '../types';
import { firebaseService } from '../services/FirebaseService';
import { logger } from '../services/LogService';

interface Estado {
  usuarios: Usuario[];
  vendas: Venda[];
  metas: Meta[];
  produtos: Produto[];
  usuarioAtual: Usuario | null;
  
  setUsuarios: (usuarios: Usuario[]) => void;
  setVendas: (vendas: Venda[]) => void;
  setMetas: (metas: Meta[]) => void;
  setProdutos: (produtos: Produto[]) => void;
  
  login: (email: string, senha: string) => Promise<void>;
  logout: () => void;
  setUsuarioAtual: (usuario: Usuario | null) => void;
  
  adicionarUsuario: (usuario: Omit<Usuario, 'id'>) => Promise<void>;
  editarUsuario: (usuario: Usuario) => Promise<void>;
  removerUsuario: (id: string) => Promise<void>;
  
  adicionarVenda: (venda: Omit<Venda, 'id'>) => Promise<void>;
  editarVenda: (venda: Venda) => Promise<void>;
  
  adicionarMeta: (meta: Omit<Meta, 'id'>) => Promise<void>;
  editarMeta: (meta: Meta) => Promise<void>;
  removerMeta: (id: string) => Promise<void>;
  
  adicionarProduto: (produto: Omit<Produto, 'id'>) => Promise<void>;
  editarProduto: (produto: Produto) => Promise<void>;
  removerProduto: (id: string) => Promise<void>;
}

export const useStore = create<Estado>((set, get) => ({
  usuarios: [],
  vendas: [],
  metas: [],
  produtos: [],
  usuarioAtual: null,

  setUsuarios: (usuarios) => set({ usuarios }),
  setVendas: (vendas) => set({ vendas }),
  setMetas: (metas) => set({ metas }),
  setProdutos: (produtos) => set({ produtos }),

  login: async (email, senha) => {
    try {
      const usuarios = await firebaseService.getUsuarios();
      const usuario = usuarios.find(u => u.email === email && u.senha === senha);
      
      if (!usuario) {
        throw new Error('Email ou senha inválidos');
      }

      set({ usuarioAtual: usuario });
      logger.info('Login realizado com sucesso', { email });
    } catch (error) {
      logger.error('Erro no login', error as Error);
      throw error;
    }
  },

  logout: () => {
    set({ usuarioAtual: null });
    logger.info('Logout realizado');
  },

  setUsuarioAtual: (usuario) => set({ usuarioAtual: usuario }),

  adicionarUsuario: async (dadosUsuario) => {
    try {
      const id = await firebaseService.addUsuario(dadosUsuario);
      const novoUsuario = { ...dadosUsuario, id };
      set(state => ({ usuarios: [...state.usuarios, novoUsuario] }));
      logger.info('Usuário adicionado com sucesso', { id });
    } catch (error) {
      logger.error('Erro ao adicionar usuário', error as Error);
      throw error;
    }
  },

  editarUsuario: async (usuario) => {
    try {
      await firebaseService.updateUsuario(usuario.id, usuario);
      set(state => ({
        usuarios: state.usuarios.map(u => u.id === usuario.id ? usuario : u)
      }));
      logger.info('Usuário atualizado com sucesso', { id: usuario.id });
    } catch (error) {
      logger.error('Erro ao editar usuário', error as Error);
      throw error;
    }
  },

  removerUsuario: async (id) => {
    try {
      await firebaseService.deleteUsuario(id);
      set(state => ({
        usuarios: state.usuarios.filter(u => u.id !== id)
      }));
      logger.info('Usuário removido com sucesso', { id });
    } catch (error) {
      logger.error('Erro ao remover usuário', error as Error);
      throw error;
    }
  },

  adicionarVenda: async (dadosVenda) => {
    try {
      const id = await firebaseService.addVenda(dadosVenda);
      const novaVenda = { ...dadosVenda, id };
      set(state => ({ vendas: [...state.vendas, novaVenda] }));
      logger.info('Venda adicionada com sucesso', { id });
    } catch (error) {
      logger.error('Erro ao adicionar venda', error as Error);
      throw error;
    }
  },

  editarVenda: async (venda) => {
    try {
      await firebaseService.updateUsuario(venda.id, venda);
      set(state => ({
        vendas: state.vendas.map(v => v.id === venda.id ? venda : v)
      }));
      logger.info('Venda atualizada com sucesso', { id: venda.id });
    } catch (error) {
      logger.error('Erro ao editar venda', error as Error);
      throw error;
    }
  },

  adicionarMeta: async (dadosMeta) => {
    try {
      const id = await firebaseService.addMeta(dadosMeta);
      const novaMeta = { ...dadosMeta, id };
      set(state => ({ metas: [...state.metas, novaMeta] }));
      logger.info('Meta adicionada com sucesso', { id });
    } catch (error) {
      logger.error('Erro ao adicionar meta', error as Error);
      throw error;
    }
  },

  editarMeta: async (meta) => {
    try {
      await firebaseService.updateUsuario(meta.id, meta);
      set(state => ({
        metas: state.metas.map(m => m.id === meta.id ? meta : m)
      }));
      logger.info('Meta atualizada com sucesso', { id: meta.id });
    } catch (error) {
      logger.error('Erro ao editar meta', error as Error);
      throw error;
    }
  },

  removerMeta: async (id) => {
    try {
      await firebaseService.deleteUsuario(id);
      set(state => ({
        metas: state.metas.filter(m => m.id !== id)
      }));
      logger.info('Meta removida com sucesso', { id });
    } catch (error) {
      logger.error('Erro ao remover meta', error as Error);
      throw error;
    }
  },

  adicionarProduto: async (dadosProduto) => {
    try {
      const id = await firebaseService.addProduto(dadosProduto);
      const novoProduto = { ...dadosProduto, id };
      set(state => ({ produtos: [...state.produtos, novoProduto] }));
      logger.info('Produto adicionado com sucesso', { id });
    } catch (error) {
      logger.error('Erro ao adicionar produto', error as Error);
      throw error;
    }
  },

  editarProduto: async (produto) => {
    try {
      await firebaseService.updateUsuario(produto.id, produto);
      set(state => ({
        produtos: state.produtos.map(p => p.id === produto.id ? produto : p)
      }));
      logger.info('Produto atualizado com sucesso', { id: produto.id });
    } catch (error) {
      logger.error('Erro ao editar produto', error as Error);
      throw error;
    }
  },

  removerProduto: async (id) => {
    try {
      await firebaseService.deleteUsuario(id);
      set(state => ({
        produtos: state.produtos.filter(p => p.id !== id)
      }));
      logger.info('Produto removido com sucesso', { id });
    } catch (error) {
      logger.error('Erro ao remover produto', error as Error);
      throw error;
    }
  }
}));