import React, { useState } from 'react';
import { DndContext, DragEndEvent, DragOverlay, closestCorners } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { KanbanColuna, KanbanCard } from '../../types';
import { KanbanColumn } from './KanbanColumn';
import { KanbanCardComponent } from './KanbanCard';
import { Plus, Filter } from 'lucide-react';

const colunasPadrao: KanbanColuna[] = [
  { id: 'backlog', titulo: 'Backlog', cards: [] },
  { id: 'todo', titulo: 'A Fazer', cards: [] },
  { id: 'doing', titulo: 'Em Andamento', cards: [] },
  { id: 'review', titulo: 'Em Revisão', cards: [] },
  { id: 'done', titulo: 'Concluído', cards: [] }
];

export const KanbanBoard: React.FC = () => {
  const [colunas, setColunas] = useState<KanbanColuna[]>(colunasPadrao);
  const [activeCard, setActiveCard] = useState<KanbanCard | null>(null);
  const [filtros, setFiltros] = useState({
    prioridade: '',
    responsavel: '',
    etiqueta: ''
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over) return;

    const activeColId = active.data.current?.columnId;
    const overColId = over.data.current?.columnId || over.id;

    if (activeColId !== overColId) {
      setColunas(colunas => {
        const activeCol = colunas.find(col => col.id === activeColId);
        const overCol = colunas.find(col => col.id === overColId);

        if (!activeCol || !overCol) return colunas;

        const activeCardIndex = activeCol.cards.findIndex(card => card.id === active.id);
        const card = activeCol.cards[activeCardIndex];

        return colunas.map(col => {
          if (col.id === activeColId) {
            return {
              ...col,
              cards: col.cards.filter(card => card.id !== active.id)
            };
          }
          if (col.id === overColId) {
            return {
              ...col,
              cards: [...col.cards, card]
            };
          }
          return col;
        });
      });
    }

    setActiveCard(null);
  };

  const handleDragStart = (event: any) => {
    const { active } = event;
    const activeCol = colunas.find(col => col.id === active.data.current?.columnId);
    const card = activeCol?.cards.find(card => card.id === active.id);
    if (card) {
      setActiveCard(card);
    }
  };

  const adicionarCard = (colunaId: string) => {
    const novoCard: KanbanCard = {
      id: Date.now().toString(),
      titulo: 'Novo Card',
      descricao: '',
      prioridade: 'media',
      etiquetas: []
    };

    setColunas(colunas.map(coluna => {
      if (coluna.id === colunaId) {
        return {
          ...coluna,
          cards: [...coluna.cards, novoCard]
        };
      }
      return coluna;
    }));
  };

  const handleEditCard = (cardId: string, cardAtualizado: KanbanCard) => {
    setColunas(colunas.map(coluna => ({
      ...coluna,
      cards: coluna.cards.map(card => 
        card.id === cardId ? cardAtualizado : card
      )
    })));
  };

  const filtrarCards = (cards: KanbanCard[]) => {
    return cards.filter(card => {
      const atendePrioridade = !filtros.prioridade || card.prioridade === filtros.prioridade;
      const atendeResponsavel = !filtros.responsavel || card.responsavel?.id === filtros.responsavel;
      const atendeEtiqueta = !filtros.etiqueta || card.etiquetas?.includes(filtros.etiqueta);
      
      return atendePrioridade && atendeResponsavel && atendeEtiqueta;
    });
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Kanban</h2>
        
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <Filter className="w-5 h-5 text-gray-500" />
            <select
              value={filtros.prioridade}
              onChange={(e) => setFiltros({ ...filtros, prioridade: e.target.value })}
              className="px-3 py-1 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              <option value="">Todas as prioridades</option>
              <option value="baixa">Baixa</option>
              <option value="media">Média</option>
              <option value="alta">Alta</option>
            </select>
          </div>
        </div>
      </div>
      
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        collisionDetection={closestCorners}
      >
        <div className="flex gap-4 overflow-x-auto pb-4">
          {colunas.map(coluna => (
            <div key={coluna.id} className="flex-shrink-0 w-80">
              <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="font-semibold text-gray-700 dark:text-gray-300">
                    {coluna.titulo}
                  </h3>
                  <button
                    onClick={() => adicionarCard(coluna.id)}
                    className="p-1 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                    title="Adicionar card"
                  >
                    <Plus className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                  </button>
                </div>
                
                <KanbanColumn
                  coluna={coluna}
                  cards={filtrarCards(coluna.cards)}
                  onEditCard={handleEditCard}
                />
              </div>
            </div>
          ))}
        </div>

        <DragOverlay>
          {activeCard && (
            <div className="transform rotate-3">
              <KanbanCardComponent card={activeCard} />
            </div>
          )}
        </DragOverlay>
      </DndContext>
    </div>
  );
};