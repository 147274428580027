import React from 'react';
import { Calendar } from 'lucide-react';
import { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, 
         startOfQuarter, endOfQuarter, startOfYear, endOfYear, subMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Interface para as props do componente
interface FiltrosPeriodoProps {
  periodo: string;
  dataInicio: Date | null;
  dataFim: Date | null;
  onPeriodoChange: (periodo: string) => void;
  onDataInicioChange: (data: Date) => void;
  onDataFimChange: (data: Date) => void;
}

export const FiltrosPeriodo: React.FC<FiltrosPeriodoProps> = ({
  periodo,
  dataInicio,
  dataFim,
  onPeriodoChange,
  onDataInicioChange,
  onDataFimChange
}) => {
  // Função para atualizar o período e as datas correspondentes
  const handlePeriodoChange = (novoPeriodo: string) => {
    const hoje = new Date();
    let novaDataInicio: Date;
    let novaDataFim: Date;

    // Define as datas com base no período selecionado
    switch (novoPeriodo) {
      case 'dia':
        novaDataInicio = startOfDay(hoje);
        novaDataFim = endOfDay(hoje);
        break;
      case 'semana':
        novaDataInicio = startOfWeek(hoje, { locale: ptBR });
        novaDataFim = endOfWeek(hoje, { locale: ptBR });
        break;
      case 'mes':
        novaDataInicio = startOfMonth(hoje);
        novaDataFim = endOfMonth(hoje);
        break;
      case 'trimestre':
        novaDataInicio = startOfQuarter(hoje);
        novaDataFim = endOfQuarter(hoje);
        break;
      case 'semestre':
        novaDataInicio = startOfMonth(subMonths(hoje, 6));
        novaDataFim = endOfMonth(hoje);
        break;
      case 'ano':
        novaDataInicio = startOfYear(hoje);
        novaDataFim = endOfYear(hoje);
        break;
      default:
        return;
    }

    onPeriodoChange(novoPeriodo);
    onDataInicioChange(novaDataInicio);
    onDataFimChange(novaDataFim);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="flex items-center gap-6">
        {/* Seletor de período predefinido */}
        <div className="flex items-center gap-2">
          <Calendar className="w-5 h-5 text-gray-500" />
          <select
            value={periodo}
            onChange={(e) => handlePeriodoChange(e.target.value)}
            className="border-0 bg-transparent text-gray-600 focus:ring-0"
          >
            <option value="dia">Hoje</option>
            <option value="semana">Esta semana</option>
            <option value="mes">Este mês</option>
            <option value="trimestre">Este trimestre</option>
            <option value="semestre">Últimos 6 meses</option>
            <option value="ano">Este ano</option>
            <option value="personalizado">Personalizado</option>
          </select>
        </div>

        {/* Seletores de data para período personalizado */}
        {periodo === 'personalizado' && (
          <div className="flex items-center gap-4">
            <input
              type="date"
              value={dataInicio ? dataInicio.toISOString().split('T')[0] : ''}
              onChange={(e) => onDataInicioChange(new Date(e.target.value))}
              className="border rounded-md px-3 py-1.5 text-sm"
            />
            <span className="text-gray-500">até</span>
            <input
              type="date"
              value={dataFim ? dataFim.toISOString().split('T')[0] : ''}
              onChange={(e) => onDataFimChange(new Date(e.target.value))}
              className="border rounded-md px-3 py-1.5 text-sm"
            />
          </div>
        )}
      </div>
    </div>
  );
};