import React from 'react';
import { useStore } from '../../../store/useStore';

interface FiltrosPeriodoProps {
  filtros: {
    dataInicio: string;
    dataFim: string;
    vendedorId: string;
  };
  setFiltros: React.Dispatch<React.SetStateAction<{
    dataInicio: string;
    dataFim: string;
    vendedorId: string;
  }>>;
}

export const FiltrosPeriodo: React.FC<FiltrosPeriodoProps> = ({
  filtros,
  setFiltros
}) => {
  const { usuarios } = useStore();
  const vendedores = usuarios.filter(u => u.tipo === 'vendedor');

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Data Início
          </label>
          <input
            type="date"
            value={filtros.dataInicio}
            onChange={(e) => setFiltros(prev => ({ ...prev, dataInicio: e.target.value }))}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Data Fim
          </label>
          <input
            type="date"
            value={filtros.dataFim}
            onChange={(e) => setFiltros(prev => ({ ...prev, dataFim: e.target.value }))}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Vendedor
          </label>
          <select
            value={filtros.vendedorId}
            onChange={(e) => setFiltros(prev => ({ ...prev, vendedorId: e.target.value }))}
            className="w-full px-3 py-2 border rounded-md"
          >
            <option value="">Todos os vendedores</option>
            {vendedores.map((vendedor) => (
              <option key={vendedor.id} value={vendedor.id}>
                {vendedor.nome}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};