import React, { useState, useEffect } from 'react';
import { useStore } from '../../../store/useStore';
import { Alert } from '../../shared/Alert';
import { validarVenda } from '../../../utils/validacao';

interface FormularioVendaProps {
  onSubmit: (venda: any) => void;
  onCancel: () => void;
}

export const FormularioVenda: React.FC<FormularioVendaProps> = ({
  onSubmit,
  onCancel
}) => {
  const { usuarios, metas, produtos } = useStore();
  const vendedores = usuarios.filter(u => u.tipo === 'vendedor');
  const produtosAtivos = produtos.filter(p => p.ativo);
  
  const [erro, setErro] = useState<string | null>(null);
  const [vendedorSelecionado, setVendedorSelecionado] = useState<string>('');
  const [produtoSelecionado, setProdutoSelecionado] = useState<string>('');
  const [tipoVenda, setTipoVenda] = useState<'valor' | 'quantidade'>('valor');
  const [valorManual, setValorManual] = useState<string>('');

  // Atualiza o valor quando um produto é selecionado
  useEffect(() => {
    if (produtoSelecionado) {
      const produto = produtos.find(p => p.id === produtoSelecionado);
      if (produto) {
        setValorManual(produto.preco.toString());
      }
    }
  }, [produtoSelecionado, produtos]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErro(null);

    if (!vendedorSelecionado) {
      setErro('Selecione um vendedor');
      return;
    }

    const valor = Number(valorManual);
    if (isNaN(valor) || valor <= 0) {
      setErro('Valor inválido');
      return;
    }

    const venda = {
      vendedorId: vendedorSelecionado,
      produtoId: produtoSelecionado || undefined,
      valor: tipoVenda === 'valor' ? valor : 0,
      quantidade: tipoVenda === 'quantidade' ? valor : 1,
      data: new Date((e.target as HTMLFormElement).data.value),
      origem: 'manual' as const
    };

    const { valido, erros } = validarVenda(venda);
    if (!valido) {
      setErro(erros.join(', '));
      return;
    }

    try {
      onSubmit(venda);
    } catch (error) {
      setErro(error instanceof Error ? error.message : 'Erro ao registrar venda');
    }
  };

  // Verifica se o vendedor tem meta ativa
  const metaAtiva = vendedorSelecionado ? metas.find(meta => {
    const hoje = new Date();
    return meta.vendedorId === vendedorSelecionado &&
           hoje >= new Date(meta.dataInicio) &&
           hoje <= new Date(meta.dataFim);
  }) : null;

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Vendedor
        </label>
        <select
          value={vendedorSelecionado}
          onChange={(e) => setVendedorSelecionado(e.target.value)}
          className="w-full px-3 py-2 border rounded-md"
          required
        >
          <option value="">Selecione um vendedor</option>
          {vendedores.map((vendedor) => (
            <option key={vendedor.id} value={vendedor.id}>
              {vendedor.nome}
            </option>
          ))}
        </select>
      </div>

      {metaAtiva && (
        <div className="bg-blue-50 p-3 rounded-md text-sm">
          <p className="font-medium text-blue-800">Meta Atual:</p>
          <p className="text-blue-600">
            {metaAtiva.tipo === 'valor' 
              ? `Meta por valor: R$ ${metaAtiva.valorMeta?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` 
              : `Meta por quantidade: ${metaAtiva.quantidadeMeta} unidades`}
          </p>
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Produto (opcional)
        </label>
        <select
          value={produtoSelecionado}
          onChange={(e) => setProdutoSelecionado(e.target.value)}
          className="w-full px-3 py-2 border rounded-md"
        >
          <option value="">Selecione um produto</option>
          {produtosAtivos.map((produto) => (
            <option key={produto.id} value={produto.id}>
              {produto.nome} - {produto.preco.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              })}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Tipo de Venda
        </label>
        <select
          value={tipoVenda}
          onChange={(e) => setTipoVenda(e.target.value as 'valor' | 'quantidade')}
          className="w-full px-3 py-2 border rounded-md"
          required
        >
          <option value="valor">Venda por Valor</option>
          <option value="quantidade">Venda por Quantidade</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {tipoVenda === 'valor' ? 'Valor da Venda (R$)' : 'Quantidade'}
        </label>
        <input
          type="number"
          value={valorManual}
          onChange={(e) => setValorManual(e.target.value)}
          min={tipoVenda === 'valor' ? "0.01" : "1"}
          step={tipoVenda === 'valor' ? "0.01" : "1"}
          className="w-full px-3 py-2 border rounded-md"
          required
          placeholder={tipoVenda === 'valor' ? 'Digite o valor' : 'Digite a quantidade'}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Data e Hora
        </label>
        <input
          type="datetime-local"
          name="data"
          className="w-full px-3 py-2 border rounded-md"
          required
        />
      </div>

      <div className="flex justify-end gap-4 mt-6">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md"
        >
          Registrar Venda
        </button>
      </div>
    </form>
  );
};