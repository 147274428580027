import React from 'react';
import { Alert } from '../shared/Alert';
import { FotoPerfilForm } from './FotoPerfilForm';
import { AlterarSenhaForm } from './AlterarSenhaForm';
import { usePerfilUsuario } from '../../hooks/usePerfilUsuario';

export const UserProfileSettings: React.FC = () => {
  const {
    usuario,
    erro,
    sucesso,
    setErro,
    setSucesso,
    handleFotoChange,
    handleRemoverFoto,
    handleAlterarSenha
  } = usePerfilUsuario();

  if (!usuario) return null;

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6">Configurações do Perfil</h2>

      {erro && (
        <Alert 
          tipo="erro" 
          mensagem={erro} 
          onClose={() => setErro(null)} 
        />
      )}

      {sucesso && (
        <Alert 
          tipo="sucesso" 
          mensagem={sucesso} 
          onClose={() => setSucesso(null)} 
        />
      )}

      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h3 className="text-lg font-semibold mb-4">Foto do Perfil</h3>
        <FotoPerfilForm
          usuario={usuario}
          onFotoChange={handleFotoChange}
          onRemoverFoto={handleRemoverFoto}
        />
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-lg font-semibold mb-4">Alterar Senha</h3>
        <AlterarSenhaForm onSubmit={handleAlterarSenha} />
      </div>
    </div>
  );
};